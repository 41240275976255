import { connect } from 'react-redux'

import fetchBallotPositionCandidates from 'actions/fetchBallotPositionCandidates'
import selectCandidate from 'actions/selectCandidate'
import selectIssue from 'actions/selectIssue'
import selectMeasure from 'actions/selectMeasure'
import selectPosition from 'actions/selectPosition'
import { Candidate, Position, StoreState } from 'types'
import { Props } from './BallotLink'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import getPathForApp from 'utils/getPathForApp'

const mapStateToProps = (
  { auth: { user }, currentCandidate, currentPosition }: StoreState,
  { candidate, history, issue, measure, position, to }: Partial<Props>,
): { to: any; currentCandidate: Candidate; currentPosition: Position } => {
  let pathname = null
  const rootPath = getPathForApp('voter_guide')

  if (measure) {
    pathname = `${rootPath}measures/${measure.slug ? measure.slug : measure.id}`
  } else if (position && candidate) {
    pathname = `${rootPath}positions/${
      position.slug ? position.slug : position.id
    }/candidates/${candidate.slug ? candidate.slug : candidate.id}`
  } else if (position && issue) {
    pathname = `${rootPath}positions/${
      position.slug ? position.slug : position.id
    }/issues/${issue.id}`
  } else if (position) {
    pathname = `${rootPath}positions/${
      position.slug ? position.slug : position.id
    }`
  } else {
    pathname = to
  }

  return {
    to: { pathname, search: user.ballot_id ? null : history?.location?.search },
    currentPosition,
    currentCandidate,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, null, AnyAction>,
  { candidate, issue, measure, position }: Props,
) => ({
  onClick: () => {
    dispatch(selectCandidate(candidate))
    dispatch(selectIssue(issue))
    dispatch(selectMeasure(measure))
    dispatch(selectPosition(position))

    if (position && position.position_election_id) {
      dispatch(fetchBallotPositionCandidates(position.position_election_id))
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
