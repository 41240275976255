import { connect } from 'react-redux'
import { StoreState } from 'types'

const mapStateToProps = ({
  appConfig: { debug, tenant },
  auth: { user },
}: StoreState) => ({
  ballotId: user.ballot_id,
  debug,
  tenantId: tenant.id,
})

export default connect(mapStateToProps)
