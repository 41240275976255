import React from 'react'
import styles from './listItems.module.scss'
import ListItem from '../../molecules/ListItem'
import { Item } from 'types'
import classNames from 'classnames'

export interface ListItemsProps {
  borders?: boolean
  onClick?(event: React.MouseEvent): void
  listItems: Item[]
  selectedId?: string
}

const ListItems: React.FC<ListItemsProps> = ({
  borders = true,
  listItems,
  onClick,
  selectedId,
}) => (
  <ul
    className={classNames(
      styles.container,
      listItems.some(i => !!i.description) && styles.repListContainer,
    )}
    data-testid="listitems"
    data-cy="searchresults"
  >
    {listItems.map((object: Item, key: number) => {
      return (
        <li
          className={classNames(
            styles.listItem,
            selectedId === object.id && styles.selected,
            borders && styles.border,
            listItems.some(i => !!i.description) &&
              styles.meetYourRepListStyles,
          )}
          data-testid="listitems-li"
          data-cy="searchresults-li"
          key={key}
        >
          <ListItem
            onClick={(event: React.MouseEvent) => onClick && onClick(event)}
            listItem={object}
            meetYourRep={listItems.some(i => !!i.description)}
          />
        </li>
      )
    })}
  </ul>
)

export default ListItems
