import React from 'react'
import classNames from 'classnames'
import styles from './spinner.module.scss'

export interface SpinnerProps {
  className?: string
  size?: 'lg' | 'sm' | 'xs'
}

const Spinner: React.FC<SpinnerProps> = ({ className, size }) => (
  <div
    data-testid="spinner"
    className={classNames(
      styles.Spinner,
      styles[`Spinner--${size}`],
      className,
    )}
  />
)

export default Spinner
