export const sliceLocale = customLocale => {
  if (customLocale) {
    const localeParts = customLocale.split('-')

    if (
      localeParts.length > 2 &&
      localeParts[localeParts.length - 1].toLowerCase() !== 'us'
    ) {
      return localeParts.slice(0, localeParts.length - 1).join('-')
    }
  }

  return customLocale
}
