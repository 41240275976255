import { connect } from 'react-redux'
import signInAndReload from 'actions/signInAndReload'
import requestPasswordReset from 'actions/requestPasswordReset'

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch, { history }) => ({
  onSubmit: ({ email, mode, password }) => {
    if (mode === 'reset_pw') {
      return dispatch(requestPasswordReset(email))
    }

    return dispatch(
      signInAndReload(history, 'password', {
        auth_id: email,
        email,
        password,
      }),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
