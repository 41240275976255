import React, { FormEvent, MouseEvent } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import Spinner from '../Spinner'
import styles from './button.module.scss'
import { Link } from 'react-router-dom'
import { ButtonVariantType, ButtonSizeType } from 'types'

// props to audit
// theme? 1 candidate support buttons

// variants to audit
// secondary 1 thankyouscene

// todos:
// many of the variant danger buttons are in check reg flow  and should use next step button
// grab tests from button.test.js

// accessibility todos:

// h2. Link

// Full information: https://a11yengineer.com/thing/link/
// h3. Success Criteria

// Description: Purpose is clear
// Role: Identifies itself as a link
// Relationships: Has a valid href pointing to a uri
// State: n/a
// Focus: Is visibly focusable with tab / swipe
// Action: Enter key / double tap
// h3. Keyboard & Screenreader Support

// Tab / swipe, Focuses the link
// Enter / double tap, Activates the link

export interface ButtonProps {
  block?: boolean
  bold?: boolean
  btnRef?: React.RefObject<HTMLInputElement>
  busy?: boolean
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  form?: string
  href?: string
  icon?: IconDefinition
  id?: string
  onClick?(event: MouseEvent | FormEvent): void
  opensModal?: boolean
  outline?: boolean
  padded?: boolean
  size?: ButtonSizeType
  square?: boolean
  title?: string
  to?: string
  type?: string
  uppercase?: boolean
  variant?: ButtonVariantType
}

const Button: React.FC<ButtonProps> = ({
  block = false,
  bold = false,
  btnRef,
  busy = false,
  children,
  className,
  disabled = false,
  href,
  icon,
  onClick,
  opensModal = false,
  outline = false,
  padded,
  size = 'md',
  square = false,
  title,
  to,
  type,
  uppercase,
  variant,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let elType: any = 'button' // TODO Type: string | typeof Link

  if (href) {
    elType = 'a'
  } else if (to) {
    elType = Link
  }

  const btnClassNames = classNames(
    styles.Button,
    block && styles.Button__block,
    bold && styles.bold,
    (busy || disabled) && styles.Button__disabled,
    icon && styles.Button__with__icon,
    icon && !children && styles.Button__with__icon__only,
    outline && styles.Button__outline,
    padded && styles.padded,
    size && styles[`Button__${size}`],
    square && styles.square,
    uppercase && styles.uppercase,
    variant && styles[`Button__${variant}`],
    className,
  )

  return React.createElement(
    elType,
    {
      'aria-haspopup': opensModal,
      className: btnClassNames,
      'data-cy': 'button',
      tabIndex: 0,
      disabled,
      href,
      onClick,
      ref: btnRef,
      rel: href && 'noopener noreferrer',
      target: href && '_blank',
      title,
      to,
      type,
      ...props,
    },
    <div className={styles.Button__inner} tabIndex={-1}>
      {busy && <Spinner className={styles.ButtonSpinner} size="sm" />}
      {icon && <FontAwesomeIcon icon={icon} />}
      {children}
    </div>,
  )
}

export default Button
