import * as types from 'actions/constants'

const candidates = (
  state = {
    didInvalidate: false,
    isFetching: false,
    items: [],
    lastUpdated: null,
  },
  action,
) => {
  switch (action.type) {
    case types.INVALIDATE_POSITION_ELECTION_CANDIDATES:
      return Object.assign({}, state, {
        didInvalidate: true,
      })
    case types.REQUEST_POSITION_ELECTION_CANDIDATES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      })
    case types.RECEIVE_POSITION_ELECTION_CANDIDATES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.payload,
        lastUpdated: action.receivedAt,
      })
    default:
      return state
  }
}

const candidatesByPositionElection = (state = {}, action) => {
  switch (action.type) {
    case types.INVALIDATE_POSITION_ELECTION_CANDIDATES:
    case types.REQUEST_POSITION_ELECTION_CANDIDATES:
    case types.RECEIVE_POSITION_ELECTION_CANDIDATES:
      return Object.assign({}, state, {
        [action.positionElectionId]: candidates(
          state[action.positionElectionId],
          action,
        ),
      })
    case types.RESET_ALL_POSITION_ELECTION_CANDIDATES:
      return {}
    default:
      return state
  }
}

export default candidatesByPositionElection
