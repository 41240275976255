import React from 'react'
import { Ballot } from 'types'
import AddressModalController from 'components/AddressModalController/AddressModalController'
import { AddressBoxInner } from 'components/sparkles'

interface Props {
  ballot: Ballot
  changeAddressLinkText?: string
  heading: string
  withBackground?: boolean
}

const AddressBox = ({
  ballot,
  changeAddressLinkText,
  heading,
  withBackground = true,
}: Props) => {
  return (
    <div>
      {changeAddressLinkText && (
        <AddressModalController openModalClickStreamEvent="Registration flow">
          {({ onClick }) => {
            return (
              <AddressBoxInner
                ballot={ballot}
                changeAddressLinkText={changeAddressLinkText}
                heading={heading}
                onClick={onClick}
                withBackground={withBackground}
              />
            )
          }}
        </AddressModalController>
      )}
    </div>
  )
}

export default AddressBox
