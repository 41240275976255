import React from 'react'
import classNames from 'classnames'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Button, PlaceField } from 'components/sparkles'
import leadFormContainer from './leadFormContainer'
import styles from './LeadForm.module.scss'
import { TenantConfig } from 'appConfig'
import { ButtonVariantType } from 'types'

export interface Props {
  askAddress?: boolean
  askZip?: boolean
  buttonClassName: string
  buttonText?: string
  buttonVariant?: ButtonVariantType
  className: string
  currentAddress?: string
  currentEmail?: string
  onSubmit(lead: object): void
  inputClassName?: string
  sampleBallotAddress: string
  tenant: TenantConfig
}

const LeadForm: React.FC<Props> = ({
  askAddress,
  askZip,
  buttonClassName,
  buttonText,
  buttonVariant,
  className,
  currentAddress,
  currentEmail,
  inputClassName,
  onSubmit,
  sampleBallotAddress,
  tenant,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Formik
      initialValues={{
        address: currentAddress || '',
        email: currentEmail || '',
      }}
      onSubmit={values =>
        onSubmit(
          Object.assign({}, values, {
            lead_type: 1,
            tenant_id: tenant.id,
          }),
        )
      }
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          className={classNames(styles.form, className)}
          onSubmit={handleSubmit}
        >
          <Field
            className={classNames(styles.textField, inputClassName)}
            name="email"
            placeholder={t('LeadForm.placeholderEmail')}
            required
            type="email"
          />
          {(askAddress || askZip) && (
            <Field
              component={PlaceField}
              inputClassName={classNames(styles.textField, inputClassName)}
              name="address"
              placeholder={
                askZip
                  ? t('LeadForm.placeholderZip')
                  : t('LeadForm.placeholderAddress', {
                      address: sampleBallotAddress,
                    })
              }
              required={askZip}
            />
          )}
          <Button
            className={classNames(styles.submitButton, buttonClassName)}
            disabled={isSubmitting}
            type="submit"
            variant={buttonVariant}
          >
            {buttonText || t('LeadForm.signUp')}
          </Button>
        </form>
      )}
    </Formik>
  )
}

LeadForm.defaultProps = {
  askAddress: false,
  askZip: false,
  buttonVariant: 'success',
}

export default leadFormContainer(LeadForm)
