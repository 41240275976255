import React, { useRef, useEffect } from 'react'
import styles from './redirectModal.module.scss'
import { Button, Markdown } from '../../atoms'
import { RedirectArrow } from './redirectArrow'

interface Props {
  closeModal(): void
  body: string
  buttonText: string
  heading: string
  fallbackLinkTarget: string
  fallbackLinkText: string
}

const RedirectModal = ({
  body,
  buttonText,
  closeModal,
  heading,
  fallbackLinkTarget,
  fallbackLinkText,
}: Props) => {
  const modalRef = useRef<HTMLInputElement>() as any
  const handleClickOutside = (e: any) => {
    const ref = modalRef.current
    if (ref && !ref.contains(e.target)) {
      closeModal()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return (
    <div className={styles.modalOverlay} data-cy="redirect_modal">
      <div
        aria-labelledby="redirect-modal-heading"
        aria-modal="true"
        className={styles.modal}
        ref={modalRef}
        role="dialog"
        tabIndex={-1}
      >
        <RedirectArrow />
        <h2 id="redirect-modal-heading">{heading}</h2>
        <Markdown source={body} />
        <a
          href={fallbackLinkTarget}
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {fallbackLinkText}
        </a>
        <Button
          className={styles.button}
          data-cy="redirect_modal_button"
          onClick={closeModal}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default RedirectModal
