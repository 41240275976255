import apiDateOptionFactory from 'utils/apiDateOptionFactory'
import {
  MailFlowOption,
  MAPTVSelections,
  PlanFlowOption,
  PollingDay,
} from 'types'
import ApiDate from 'utils/ApiDate'
import dateTimeToInt from 'utils/dateTimeToInt'
import {
  BEGIN_IN_PERSON_FLOW,
  RECEIVE_BALLOT,
  RECEIVE_ELECTION,
  RECEIVE_POLLING_DAYS,
  SELECT_DATE_TIME,
  SELECT_FLOW,
  SELECT_IF_BALLOT_NEEDED,
  SELECT_MAIL_FLOW,
  SELECT_POLLING_DAY,
  SELECT_POLLING_PLACE,
  SET_ALL_MAPTV_OPTIONS,
} from 'actions/constants'
import { AnyAction } from 'redux'

const initialState: MAPTVSelections = {
  electionHasCaucus: false,
  hasBallot: undefined,
  needToRequestBallot: undefined,
  planStarted: false,
  pollingDays: [],
  selectedDateTime: undefined,
  selectedFlow: undefined,
  selectedMailFlow: undefined,
  selectedPollingDay: undefined,
  selectedPollingPlace: undefined,
}

export default (state = initialState, action: AnyAction): MAPTVSelections => {
  switch (action.type) {
    case BEGIN_IN_PERSON_FLOW:
      return {
        ...state,
        planStarted: action.value,
      }
    case RECEIVE_BALLOT:
      // Reset choices with new ballot
      return {
        ...state,
        hasBallot: undefined,
        needToRequestBallot: undefined,
        selectedDateTime: undefined,
        selectedFlow: undefined,
        selectedMailFlow: undefined,
        selectedPollingDay: undefined,
        selectedPollingPlace: undefined,
      }
    case SELECT_DATE_TIME:
      return {
        ...state,
        selectedDateTime: action.date,
      }
    case SELECT_FLOW:
      let newPollingDay = state.selectedPollingDay
      if (
        state.pollingDays &&
        state.pollingDays.findIndex((p: PollingDay) => p.early_voting) !== -1 &&
        state.pollingDays.length > 1
      ) {
        // If there are options for polling days or early voting, then we reset the choice
        newPollingDay = undefined
      }
      return {
        ...state,
        selectedFlow: action.value,
        selectedMailFlow: undefined,
        selectedPollingDay: newPollingDay,
      }
    case SELECT_MAIL_FLOW:
      return {
        ...state,
        selectedMailFlow: action.value,
        selectedPollingPlace: undefined,
      }
    case SELECT_POLLING_DAY:
      return {
        ...state,
        selectedPollingDay: action.pollingDay,
        selectedDateTime: undefined,
        selectedPollingPlace: undefined,
      }
    case SELECT_IF_BALLOT_NEEDED:
      return {
        ...state,
        // needToRequestBallot: action.value,
        hasBallot: action.value,
      }
    case SELECT_POLLING_PLACE:
      if (action.pollingPlace) {
        const electionDay =
          action.pollingPlace.polling_days.find(
            (d: PollingDay) =>
              state.selectedPollingDay &&
              d.date === state.selectedPollingDay.date,
          ) || null
        const timeOptions =
          electionDay &&
          apiDateOptionFactory(
            new ApiDate(electionDay.open_at),
            new ApiDate(electionDay.close_at),
          )
        let dateTime
        if (timeOptions && timeOptions.length === 1) {
          dateTime = timeOptions[0]
        }
        return {
          ...state,
          selectedPollingPlace: action.pollingPlace,
          selectedDateTime: dateTime,
          selectedPollingDay: electionDay,
        }
      } else {
        return {
          ...state,
        }
      }
    case SET_ALL_MAPTV_OPTIONS:
      return {
        ...state,
        ...action.payload,
      }
    case RECEIVE_ELECTION:
      const election = action.payload

      if (!election) {
        return {
          ...state,
          selectedFlow: undefined,
          selectedMailFlow: undefined,
        }
      }
      const newState = {
        ...state,
        electionHasCaucus: election.caucus,
        needToRequestBallot: !!election.deadline_request_ballot,
      }
      if (election.caucus && state.pollingDays.length !== 0) {
        // If there's no early voting
        if (
          state.pollingDays.findIndex((p: PollingDay) => !p.early_voting) === -1
        ) {
          const electionDay = state.pollingDays.filter(
            (day: PollingDay) => day.date === election.electionDay,
          )[0]
          return {
            ...newState,
            selectedFlow: 'caucus',
            selectedPollingDay: electionDay,
            selectedDateTime: electionDay && new ApiDate(electionDay.open_at),
          }
        } else {
          return {
            ...newState,
            selectedFlow: 'caucus',
          }
        }
      }
      const { deadline_drop_off, deadline_mail_in_utc, in_person_voting } =
        election
      const today = dateTimeToInt(new Date().toISOString())
      const deadlineMailIn =
        deadline_mail_in_utc && dateTimeToInt(deadline_mail_in_utc)

      // Handle mail flow
      let mailFlow: MailFlowOption | undefined = undefined

      if (deadlineMailIn && today <= deadlineMailIn) {
        mailFlow = 'mail-in'
      } else if (
        deadline_drop_off &&
        today <= dateTimeToInt(deadline_drop_off)
      ) {
        mailFlow = 'drop-off'
      }

      // Handle regular flow
      let flowOption: PlanFlowOption
      if (!in_person_voting) {
        flowOption = 'by-mail'
      } else if (in_person_voting && deadline_mail_in_utc) {
        return newState
      } else if (deadline_mail_in_utc) {
        flowOption = 'by-mail'
      } else {
        flowOption = 'in-person'
      }

      return {
        ...newState,
        selectedFlow: flowOption,
        selectedMailFlow: mailFlow,
      }
    case RECEIVE_POLLING_DAYS:
      const { payload } = action
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const relevantPollingDays = payload.filter(
        (day: PollingDay) => day.timezone === localTimezone,
      )
      const baseNewState = {
        ...state,
        pollingDays: relevantPollingDays,
      }
      if (state.selectedFlow === 'caucus' || state.electionHasCaucus) {
        const electionDayOptions = relevantPollingDays.filter(
          (p: PollingDay) => !p.early_voting,
        )
        if (electionDayOptions && electionDayOptions.length > 0) {
          const electionDay = electionDayOptions[0]
          return {
            ...baseNewState,
            selectedFlow: 'caucus',
            selectedPollingDay: electionDay,
            selectedDateTime: new ApiDate(electionDay.open_at),
          }
        } else {
          return baseNewState
        }
      } else if (payload.findIndex((p: PollingDay) => p.early_voting) !== -1) {
        // If there is early voting
        return { ...state, pollingDays: relevantPollingDays }
      } else if (relevantPollingDays.length === 1) {
        return {
          ...baseNewState,
          selectedPollingDay: relevantPollingDays[0] || null,
        }
      } else {
        return baseNewState
      }
    default:
      return state
  }
}
