import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import ApiDate from 'utils/ApiDate'
import { Election } from 'types'
import styles from './UpcomingElectionBox.module.css'

export interface Props {
  election: Election
}

const UpcominElectionBox: React.FC<Props> = ({ election }) => {
  const { i18n } = useTranslation()

  return (
    <>
      <h1 className={styles.title}>{election.name}</h1>
      <time className={styles.electionDay} dateTime={election.election_day}>
        <FontAwesomeIcon className={styles.calenderIcon} icon={faCalendarAlt} />
        {new ApiDate(election.election_day).toLocaleDateString(i18n.language, {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })}
      </time>
    </>
  )
}

export default UpcominElectionBox
