import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage, Formik, Form, Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil'

import { Button, Markdown } from 'components/sparkles'
import './SelectionComment.scss'

class SelectionComment extends React.Component {
  static propTypes = {
    author: PropTypes.string,
    editable: PropTypes.bool,
    onSubmit: PropTypes.func,
    selection: PropTypes.object.isRequired,
  }

  static defaultProps = {
    author: 'Anonymous',
    editable: false,
  }

  state = {
    isEditing: false,
  }

  componentDidMount() {
    const { editable, selection } = this.props

    this.setState({
      isEditing:
        editable &&
        (!selection.selection_comment ||
          selection.selection_comment.length === 0),
    })
  }

  render() {
    const { author, editable, onSubmit, selection } = this.props

    const { isEditing } = this.state

    if (
      !editable &&
      (!selection.selection_comment || selection.selection_comment.length === 0)
    ) {
      return null
    } else if (isEditing) {
      return (
        <div className="SelectionComment">
          <Formik
            initialValues={{
              selection_comment: selection.selection_comment || '',
            }}
            onSubmit={values => {
              onSubmit(Object.assign({}, selection, values))
              this.setState({ isEditing: false })
            }}
            validate={values => {
              let errors = {}
              if (values.selection_comment.length > 500) {
                errors.selection_comment = 'Maximum length is 500 characters'
              }
              return errors
            }}
          >
            {({ isSubmitting }) => (
              <Form className="SelectionComment__Form">
                <Field
                  component="textarea"
                  name="selection_comment"
                  placeholder="Leave a comment to tell your friends and family why you think of voting for this candidate..."
                />
                <ErrorMessage name="selection_comment" component="div" />
                <Button
                  disabled={isSubmitting}
                  outline
                  type="submit"
                  variant="primary"
                >
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      )
    } else {
      return (
        <div className="SelectionComment">
          <Markdown
            className="SelectionComment__Comment"
            disallowedTypes={['link', 'image']}
            source={`*${author} says:*\n\n${selection.selection_comment ||
              '...'}`}
          />
          {editable && (
            <FontAwesomeIcon
              className="SelectionComment__Edit"
              icon={faPencil}
              onClick={() => this.setState({ isEditing: true })}
            />
          )}
        </div>
      )
    }
  }
}

export default SelectionComment
