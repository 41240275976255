import React from 'react'
import ReactMarkdown from 'react-markdown'

const Markdown: React.FC<{ [name: string]: any }> = props => (
  <ReactMarkdown
    {...props}
    renderers={{
      // eslint-disable-next-line react/display-name
      link: ({ href, children }) => (
        <a
          data-cy="markdown-link"
          href={href}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </a>
      ),
    }}
  />
)

export default Markdown
