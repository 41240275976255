import React from 'react'
import { Link } from 'react-router-dom'
import { OfficeHolder } from 'types'
import HeadshotImage from 'assets/images/headshot.png'
import { LinkButton } from 'components/sparkles'
import nameFormatter from 'utils/nameFormatter'
import styles from './OfficeHolderCard.module.scss'

export interface Props {
  linkToCandidate?: boolean
  person: OfficeHolder
}

const OfficeHolderCard: React.FC<Props> = ({
  person,
  linkToCandidate,
}: Props) => (
  <div className={styles.card}>
    <img
      alt={nameFormatter(person)}
      className={styles.headshot}
      src={person.thumb_url || HeadshotImage}
    />
    <div className={styles.summary}>
      <header>
        {linkToCandidate ? (
          <h3>
            <Link to={`/c/candidates/${person.candidate_id}`}>
              {nameFormatter(person)}
            </Link>
          </h3>
        ) : (
          <h3>{nameFormatter(person)}</h3>
        )}
        {person.position_name && <h4>{person.position_name}</h4>}
      </header>

      {person.phone && <div>{person.phone}</div>}

      {person.email && (
        <div>
          <a href={`mailto:${person.email}`}>{person.email}</a>
        </div>
      )}

      {Array.isArray(person.urls) && (
        <div className={styles.urls}>
          {person.urls.map((url, key) => (
            <LinkButton key={key} size="sm" {...url} />
          ))}
        </div>
      )}
    </div>
  </div>
)

export default OfficeHolderCard
