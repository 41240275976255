import * as types from 'actions/constants'

export default (state = null, { type, payload }) => {
  switch (type) {
    case types.RECEIVE_ELECTION:
      return payload
    case types.CLEAR_ELECTION:
      return null
    case types.RECEIVE_BALLOT_POSITIONS:
      return {
        ...state,
        hasRecall: !!payload.find(position => position.is_recall), // The election has recall if any position is a recall
      }
    default:
      return state
  }
}
