import React from 'react'
import { ExcuseButton } from 'components/sparkles'
import { Excuse } from 'types'
import styles from './ExcuseChoice.module.scss'

interface Props {
  onExcuseClick(excuse: string): void
  excuses: Excuse[]
  selectedExcuse: string | undefined
}

const ExcuseChoice = ({ excuses, onExcuseClick, selectedExcuse }: Props) => (
  <div className={styles.excuseChoiceContainer}>
    {excuses.map((excuse: Excuse) => {
      const isSelectedExcuse = !!selectedExcuse && excuse.key === selectedExcuse
      return (
        <ExcuseButton
          excuse={excuse}
          isSelectedExcuse={isSelectedExcuse}
          onExcuseClick={onExcuseClick}
          key={excuse.key}
        />
      )
    })}
  </div>
)

export default ExcuseChoice
