import {
  RECEIVE_POSITION,
  RECEIVE_BALLOT_POSITIONS,
  REQUEST_POSITION,
  SELECT_POSITION,
} from 'actions/constants'

export default (state = null, action) => {
  switch (action.type) {
    case SELECT_POSITION:
      return action.position || null

    case RECEIVE_POSITION:
      return action.payload

    // When receiving a list of positions update locally selected position with
    // position from API.
    case RECEIVE_BALLOT_POSITIONS:
      if (state) {
        return (
          action.payload.find(
            position =>
              position.slug === state.slug || position.id === state.id,
          ) || null
        )
      } else {
        return state
      }
    case REQUEST_POSITION:
      return null
    default:
      return state
  }
}
