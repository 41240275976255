import { Experience } from 'types'

export default (a: Experience, b: Experience) => {
  const isExperienceACurrent = a.end_year && isEndYearCurrent(a)
  const isExperienceBCurrent = b.end_year && isEndYearCurrent(b)
  if (isExperienceACurrent && !isExperienceBCurrent) {
    return -1
  } else if (!isExperienceACurrent && isExperienceBCurrent) {
    return 1
  } else if (b.start_year && a.start_year) {
    // If both are current, go based on start year
    return parseInt(b.start_year.trim()) - parseInt(a.start_year.trim())
  } else if (!a.start_year && b.start_year) {
    return 1
  }
  return -1
}

function isEndYearCurrent(experience: Experience) {
  return (
    experience.end_year.trim().toLowerCase() === 'present' ||
    experience.end_year.trim().toLowerCase() === 'current'
  )
}
