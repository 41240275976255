import * as types from '../actions/constants'

export default (state = [], action) => {
  switch (action.type) {
    case types.PUSH_NOTIFICATION:
      return [action.notification, ...state]
    default:
      return state
  }
}
