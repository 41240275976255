import React from 'react'
import { Helmet } from 'react-helmet'

const SocialHelmet = ({ attributes }) => {
  if (!attributes) {
    return null
  }

  return (
    <Helmet>
      {attributes.description && (
        <meta property="og:description" content={attributes.description} />
      )}
      {attributes.imageUrl && (
        <meta property="og:image" content={attributes.imageUrl} />
      )}
      {attributes.title && (
        <meta property="og:title" content={attributes.title} />
      )}
      {attributes.imageUrl && (
        <meta name="twitter:card" content="summary_large_image" />
      )}
      {attributes.title && (
        <meta name="twitter:title" content={attributes.title} />
      )}
      {attributes.description && (
        <meta name="twitter:description" content={attributes.description} />
      )}
      {attributes.imageUrl && (
        <meta name="twitter:image" content={attributes.imageUrl} />
      )}
    </Helmet>
  )
}

export default SocialHelmet
