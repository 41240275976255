import {
  RECEIVE_CHOICE_ELECTIONS,
  REQUEST_CHOICE_ELECTIONS,
} from 'actions/constants'
import apiFetch from './apiFetch'
import { Election, StoreState } from 'types'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'

const receiveChoiceElections = (payload: Election[]) => ({
  type: RECEIVE_CHOICE_ELECTIONS,
  payload,
})
const requestChoiceElections = () => ({ type: REQUEST_CHOICE_ELECTIONS })

export default (
    ids: number[],
  ): ThunkAction<void, StoreState, unknown, AnyAction> =>
  async dispatch => {
    dispatch(requestChoiceElections())
    const param = ids.join(',')
    if (param && param.length > 0) {
      const response = await dispatch(
        apiFetch(`/elections-choice/${param}`, {
          method: 'GET',
        }),
      )

      if (!response.ok) {
        return dispatch(receiveChoiceElections([]))
      }

      const json = await response.json()

      if (json) {
        return dispatch(receiveChoiceElections(json))
      }
    }
  }
