import * as types from 'actions/constants'
import { AnyAction } from 'redux'
import { Selection } from 'types'

const initialState: {
  didInvalidate: boolean
  isFetching: boolean
  items: Selection[]
  lastUpdated: any
} = {
  didInvalidate: true, // Start out invalidated to force fetch
  isFetching: false,
  items: [],
  lastUpdated: null,
}

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.DESTROYED_SELECTION:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: state.items.filter(
          selection => selection.id !== action.payload.id,
        ),
      })

    case types.RECEIVE_SELECTIONS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.payload,
        lastUpdated: action.receivedAt,
      })

    case types.DESTROYING_SELECTION:
    case types.REQUEST_SELECTIONS:
    case types.SAVING_SELECTION:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      })

    case types.SAVED_SELECTION:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: [
          ...state.items.filter(s => s.id !== action.payload.id),
          action.payload,
        ],
      })

    default:
      return state
  }
}
