import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import AnalyticsContext from 'contexts/AnalyticsContext/AnalyticsContext'

class CalendarAppointmentButton extends React.Component {
  static contextType = AnalyticsContext
  static defaultProps = {
    label: 'Add to calendar',
  }

  componentDidMount() {
    const analytics = this.context
    if (analytics && window.addeventatc) {
      /* Capture click on button */
      window.addeventatcasync = function() {
        window.addeventatc.register('button-click', function(obj) {
          analytics.track('turnout summary: clicked add to calendar')
        })(
          /* Capture when an option in the dropdown is clicked */
          window,
        )
        window.addeventatc.register('button-dropdown-click', function(obj) {
          analytics.track('turnout summary: added to calendar', {
            service: obj.service,
          })
        })
      }
    }

    this.refresh()
  }

  componentDidUpdate() {
    this.refresh()
  }

  refresh() {
    // @ts-ignore-start
    if (window.addeventatc) {
      window.addeventatc.refresh()
    }
    // @ts-ignore-end
  }

  render() {
    const {
      className,
      clientId,
      dateTime,
      description,
      label,
      pollingPlace,
      title,
      allDayEvent,
      timezone,
    } = this.props

    if (!dateTime) {
      return null
    }

    return (
      <div className={classNames('addeventatc', className)} title={label}>
        {label}
        {clientId && <span className="client">{clientId}</span>}
        <span className="all_day_event">{allDayEvent ? 'true' : 'false'}</span>
        <span className="start">{dateTime}</span>
        <span className="title">{title}</span>
        <span className="description">{description}</span>
        {pollingPlace && (
          <span className="location">
            {[pollingPlace.name, pollingPlace.formatted_address]
              .filter(n => n)
              .join(', ')}
          </span>
        )}
        {timezone && <span className="timezone">{timezone}</span>}
      </div>
    )
  }
}

CalendarAppointmentButton.propTypes = {
  className: PropTypes.string,
  clientId: PropTypes.string,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  label: PropTypes.string,
  pollingDay: PropTypes.object,
  pollingPlace: PropTypes.object,
  title: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  allDayEvent: PropTypes.bool,
}

export default CalendarAppointmentButton
