import React from 'react'

export type TrackFn = (
  action: string,
  properties?: { [key: string]: any },
  label?: string | null,
) => Promise<any>

export interface AnalyticsContextType {
  browserName: string | null | undefined
  browserOS: string | null | undefined
  browserVersion: string | null | undefined
  initialReferrer: string | null | undefined
  initialUrl: string
  screenHeight: number
  screenWidth: number
  sessionId?: string | null
  sessionExternallySet: boolean
  track: TrackFn
  utm_campaign: string | null | undefined
  utm_content: string | null | undefined
  utm_medium: string | null | undefined
  utm_source: string | null | undefined
  utm_term: string | null | undefined
  viewportHeight: number
  viewportWidth: number
}

export default React.createContext<AnalyticsContextType | undefined>(undefined)
