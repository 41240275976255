import React from 'react'
import styles from './subNav.module.scss'
import Button from '../../atoms/Button'

export type SubNavProps<T extends NavOption> = {
  borders?: boolean
  onClick?(option: T): void
  options: T[]
  selectedId?: number | string
}

export type NavOption = {
  label: string
  key: number | string
}

const SubNav = <T extends NavOption>({
  onClick,
  options,
  selectedId,
}: SubNavProps<T>) => (
  <div className={styles.subnav} data-cy="subnav">
    {options.map(option => (
      <Button
        bold={true}
        data-cy="subnav-button"
        key={option.key}
        onClick={onClick && (() => onClick(option))}
        size="sm"
        square
        variant={selectedId === option.key ? 'primary' : 'white'}
      >
        {option.label}
      </Button>
    ))}
  </div>
)

export default SubNav
