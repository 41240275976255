import Cookies from 'js-cookie'
import { connect } from 'react-redux'
import signOut from 'actions/signOut'

const mapStateToProps = ({
  appConfig: {
    universe: { homeUrl },
  },
}) => ({
  homeUrl,
})

const mapDispatchToProps = (dispatch, { history }) => ({
  onClick: homeUrl => {
    // Remove cookie
    Cookies.remove('ce_s')

    // Clear auth params
    dispatch(signOut())

    // Send user somewhere else
    if (process.env.REACT_APP_IS_BALLOTREADY) {
      history.push('/')
    } else {
      window.location.href = homeUrl
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
