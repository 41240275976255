import React, { useState } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown'
import { faAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp'

import styles from './expander.module.scss'

export interface ExpanderProps {
  header: string
  children?: any
  disabled?: boolean
  open: boolean
  className?: string
}
const Expander: React.FC<ExpanderProps> = ({
  header,
  children,
  disabled,
  open,
  className,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(open)
  return (
    <div
      className={classNames(
        disabled
          ? `${styles.drawerContainer} ${styles.disabled} ${className}`
          : `${styles.drawerContainer} ${className}`,
      )}
      data-testid="expander"
    >
      <button
        className={classNames(
          drawerOpen
            ? `${styles.drawerOuter} ${styles.drawerOuterOpen}`
            : `${styles.drawerOuter}`,
        )}
        onClick={() => {
          if (!disabled) {
            setDrawerOpen(!drawerOpen)
          }
        }}
        data-testid="expander-header"
        aria-expanded={drawerOpen}
      >
        <h3>{header}</h3>
        <FontAwesomeIcon
          icon={drawerOpen ? faAngleUp : faAngleDown}
          size="2x"
          className={styles.drawerArrow}
        />
      </button>
      {drawerOpen && (
        <div
          className={styles.drawerInner}
          data-testid="expander-children"
          aria-hidden={!drawerOpen}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default Expander
