import { connect } from 'react-redux'
import saveBallot from 'actions/saveBallot'
import { ThunkDispatch } from 'redux-thunk'
import { StoreState } from 'types'
import { AnyAction } from 'redux'

const mapStateToProps = ({ appConfig: { supportedLocales } }: StoreState) => ({
  locales: supportedLocales,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, null, AnyAction>,
) => ({
  onLocaleChange: (newLocale: string) =>
    dispatch(saveBallot({ locale: newLocale })),
})

export default connect(mapStateToProps, mapDispatchToProps)
