import * as types from 'actions/constants'
import apiFetch from './apiFetch'

const signedIn = (service, user) => ({
  type: types.SIGNED_IN,
  service,
  user,
})

const signingIn = () => ({ type: types.SIGNING_IN })

const signingInError = (status, message) => {
  return {
    type: types.SIGNING_IN_ERROR,
    message,
    status,
  }
}

export default (service, user) => async (dispatch, getState) => {
  dispatch(signingIn())

  const {
    appConfig: { tenant },
    auth: {
      user: {
        // If there's no ballot id it's an anonymous (homepage) login attempt
        ballot_id,
      },
    },
  } = getState()

  const path = ballot_id ? '/ballot/login' : '/ballot/login/anonymous'
  const response = await dispatch(
    apiFetch(path, {
      method: 'POST',
      body: JSON.stringify({
        auth_type: service,
        tenant_id: tenant.id,
        ...user,
      }),
    }),
  )

  const json = await response.json()

  if (!response.ok) {
    return dispatch(signingInError(response.status, json.message))
  }

  if (json) {
    return dispatch(signedIn(service, json))
  }
}
