import * as types from 'actions/constants'

export default (state = 0, { type }) => {
  switch (type) {
    case types.FETCH_REQUEST:
      return state + 1
    case types.FETCH_RECEIVE:
      return state - 1
    default:
      return state
  }
}
