import * as types from 'actions/constants'

export default (state = null, { type, payload }) => {
  switch (type) {
    case types.SENT_EMAIL:
      return payload
    case types.EMAIL_ERROR:
      return { message: 'error' }
    case types.CLEAR_EMAIL:
      return null
    default:
      return state
  }
}
