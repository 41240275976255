import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe'
import { Markdown } from 'components/sparkles'
import LocaleSwitcher from '../../molecules/LocaleSwitcher'
import footerContainer from './footerContainer'
import styles from './Footer.module.scss'

interface Props {
  disclaimer?: string | null
  hasBallotSidebar?: boolean
  locales?: string[]
  onLocaleChange?: (locale: string) => void
  supportPortalUrl?: string
}

const Footer = ({
  disclaimer,
  hasBallotSidebar = false,
  locales,
  onLocaleChange,
  supportPortalUrl,
}: Props) => {
  const { i18n, t } = useTranslation()
  const currentYear = new Date().getFullYear()
  let handleLocaleChange

  if (onLocaleChange) {
    handleLocaleChange = (locale: string) => {
      i18n
        .changeLanguage(locale)
        .then(() => onLocaleChange(locale))
        .catch(err =>
          console.error('An error occurred while switching locales', err),
        )
    }
  }

  return (
    <footer
      role="contentinfo"
      className={classNames(
        styles.Footer,
        hasBallotSidebar && styles.footerWithSidebar,
      )}
    >
      <div className={styles.footerContainer}>
        {disclaimer && (
          <Markdown
            className={styles.footerDisclaimer}
            source={disclaimer}
            escapeHtml={false}
          />
        )}
        <p>
          <Trans i18nKey="sparkles:Footer.supportPortal" parent="strong">
            Need help?
            <a
              href={supportPortalUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us
            </a>
            .
          </Trans>
        </p>
        <Trans i18nKey="sparkles:Footer.rights" parent="p">
          Powered by
          <a
            href="https://ballotready.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            CivicEngine
          </a>
          . Copyright © {{ currentYear }}
          <a
            href="https://ballotready.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            CivicEngine
          </a>
          . All Rights Reserved.
        </Trans>

        <ul className={styles.footerLinks}>
          <li>
            <a
              href="https://about.ballotready.org/terms"
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('sparkles:Footer.termsOfUse')}
            </a>
          </li>
          <li>
            <a
              href="https://about.ballotready.org/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('sparkles:Footer.privacyPolicy')}
            </a>
          </li>
        </ul>

        {locales && locales.length > 1 && handleLocaleChange && (
          <div className={styles.footerLocale}>
            <FontAwesomeIcon icon={faGlobe} />
            <label id="languageSelect">Language:</label>
            <LocaleSwitcher
              className={styles.footerLocaleSwitcher}
              currentLocale={i18n.language}
              locales={locales}
              onChange={handleLocaleChange}
            />
          </div>
        )}
      </div>
    </footer>
  )
}

export default footerContainer(Footer)
