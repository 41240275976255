/**
 * Converts a JS Date object to an ISO date string (e.g., '2019-11-26')
 */
export default d => {
  const year = d.getFullYear()

  // Makes sure month is always 2 digits. Also does '+1' because JavaScript
  // months start at 0 (0 = January, 1 = February, etc.)
  const month = ('00' + (d.getMonth() + 1)).slice(-2)

  // Makes sure day is always 2 digits
  const day = ('00' + d.getDate()).slice(-2)

  return `${year}-${month}-${day}`
}
