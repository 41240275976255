import { wrap } from 'object-path-immutable'
import { ApplyFiltersAction } from '../actions/applyFilters'
import { ClearFilterAction } from '../actions/clearFilter'
import { ClearFiltersAction } from '../actions/clearFilters'
import {
  APPLY_FILTERS,
  CLEAR_FILTER,
  CLEAR_FILTERS,
} from '../actions/constants'
import { Filters } from 'types'

const initialState: Filters = {
  empty: true, // Are there anty filters?
  levels: {},
  name: '',
  state: '',
  tags: {},
}

export default (
  state = initialState,
  action: ApplyFiltersAction | ClearFilterAction | ClearFiltersAction,
): Filters => {
  switch (action.type) {
    case APPLY_FILTERS:
      return { ...action.values, empty: false }
    case CLEAR_FILTER:
      // Deletes filter key, but makes sure to keep attributes from original state
      const newState = Object.assign(
        {},
        initialState,
        wrap(state).del(action.key),
      )

      // Not so pretty but have to check whether any filters left or not
      if (
        Object.keys(newState.levels).length === 0 &&
        newState.name.length === 0 &&
        newState.state.length === 0 &&
        Object.keys(newState.tags).length === 0
      ) {
        return Object.assign({}, newState, { empty: true })
      } else {
        return newState
      }
    case CLEAR_FILTERS:
      return initialState
    default:
      return state
  }
}
