import React from 'react'
import { MetadataContents } from 'types'

import styles from './newsItem.module.scss'

interface NewsItemProps {
  newsItem: MetadataContents
}

const NewsItem: React.FC<NewsItemProps> = ({ newsItem }) => {
  return (
    <div
      data-cy="newsitem"
      data-testid={`news-${newsItem.title}`}
      className={styles.newsItem}
    >
      {newsItem.favicon && (
        <img
          src={newsItem.favicon}
          alt={`Logo of ${newsItem.rootUrl}`}
          className={styles.favicon}
        />
      )}
      <div>
        <h3 className={styles.title}>
          <a href={newsItem.url} target="_blank" rel="noopener noreferrer">
            {newsItem.title}
          </a>
        </h3>
        <div className={styles.description}>{newsItem.description}</div>
      </div>
      <img
        src={newsItem.image}
        alt={newsItem.description}
        className={styles.image}
      />
    </div>
  )
}

export default NewsItem
