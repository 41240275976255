import {
  RECEIVE_BALLOT_USER,
  SAVED_BALLOT_USER,
  RESET_PASSWORD,
  SIGNED_IN,
  SIGNING_IN,
  SIGN_OUT,
  SIGNING_IN_ERROR,
  SAVE_BALLOT_PARAMS,
} from 'actions/constants'

const initialState = {
  error: {
    message: null,
    status: null,
  },
  isSignedIn: false,
  service: null,
  user: {
    ballot_id: null,
    ballot_user_id: null,
    bio: null,
    engine_token: null,
    id: null,
    name: null,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_BALLOT_USER:
    case SAVED_BALLOT_USER:
      const { payload } = action
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          // PATCH returns `ballot_user_id`, GET has `id`
          ballot_user_id: payload.id || payload.ballot_user_id,
          bio: payload.bio,
          name: payload.name,
        }),
      })

    case RESET_PASSWORD:
      return {
        isSignedIn: true,
        service: 'password',
        user: Object.assign({}, state.user, action.user),
      }

    case SIGNED_IN:
      const { service, user } = action
      return {
        isSignedIn: true,
        service,
        user: Object.assign({}, state.user, user),
      }

    case SIGN_OUT:
      return initialState

    // Reset some state when signing in
    case SIGNING_IN:
      return Object.assign({}, state, {
        error: {
          message: null,
          status: null,
        },
        isSignedIn: false,
        service: null,
      })

    case SIGNING_IN_ERROR:
      return Object.assign({}, state, {
        error: {
          message: action.message || null,
          status: action.status || null,
        },
      })

    case SAVE_BALLOT_PARAMS:
      const { username, token, userId } = action
      return Object.assign({}, state, {
        isSignedIn: !!userId,
        user: Object.assign({}, state.user, {
          ballot_id: username,
          ballot_user_id: userId,
          engine_token: token,
        }),
      })

    default:
      return state
  }
}
