import { Ballot } from 'types'

export function twoLineAddress(
  ballot: Ballot,
): { addressLine1: string; addressLine2: string } {
  let zip = ballot?.zipcode && `${ballot.zipcode}` // Zipcode is an int :( Fixing display here
  if (zip && zip.length < 5) {
    zip = zip.padStart(5, '0')
  }

  const address1 = joinParts([ballot?.street_number, ballot?.street], ' ')
  const address2 = joinParts([
    ballot?.city,
    joinParts([ballot?.state, zip], ' '),
  ])

  return { addressLine1: address1, addressLine2: address2 }
}

const joinParts = (parts: string[], separator = ', ') =>
  parts.filter(p => p).join(separator)
