import React from 'react'
import styles from './SocialSidebar.module.scss'
import { SocialButtons } from 'components/sparkles'

interface Props {
  emailSubject: string
  shareText: string
  url: string
}

const SocialSidebar = ({ emailSubject, shareText, url }: Props) => (
  <div className={styles.socialSidebar}>
    <SocialButtons
      emailSubject={emailSubject}
      includePrint={true}
      shareText={shareText}
      website={url}
      sourcePage={'slate_card'}
    />
  </div>
)

export default SocialSidebar
