import { AnyAction } from 'redux'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

import createBallot from 'actions/createBallot'
import { AppConfig, WidgetSettings } from 'appConfig'
import { AddressFormValues, Ballot, StoreState } from 'types'
import { OwnProps } from 'components/AddressForm/AddressForm'
import { AnalyticsContextType } from 'contexts/AnalyticsContext/AnalyticsContext'

export interface StateProps {
  appConfig: AppConfig
  ballot: Ballot
  ineligible: boolean
  locales: string[]
  sampleBallotAddress: string
  widget: WidgetSettings
}

const mapStateToProps = (
  {
    appConfig,
    appConfig: {
      enableDashboard,
      enableElectionCenter,
      sampleBallotAddress,
      supportedLocales,
      widget,
    },
    ballot,
  }: StoreState,
  { ineligible }: OwnProps,
): StateProps => ({
  appConfig,
  ballot,
  ineligible:
    ineligible ||
    (!enableDashboard &&
      !enableElectionCenter &&
      ballot &&
      ballot.processed &&
      !ballot.election_id),
  locales: supportedLocales,
  sampleBallotAddress,
  widget,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, null, AnyAction>,
  { onSubmit, redirect }: OwnProps,
) => ({
  onSubmit: async (
    values: AddressFormValues,
    history: any,
    analytics?: AnalyticsContextType,
  ) => {
    if (!values.address && values.lat && values.lng) {
      values.address = `${values.lat}, ${values.lng}`
    }
    await dispatch(createBallot(values, history, redirect, analytics))

    if (onSubmit) {
      onSubmit(values)
    }
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
