import { AnyAction } from 'redux'
import { SET_IS_TIME_TRAVELING } from 'actions/constants'

const initialState: boolean = false

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_IS_TIME_TRAVELING:
      return action.payload
    default:
      return state
  }
}
