import * as types from '../actions/constants'

export default (state = false, action) => {
  switch (action.type) {
    case types.TOGGLE_SIGN_UP_PROMPT:
      return action.value
    default:
      return state
  }
}
