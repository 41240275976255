import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import './LinkButton.scss'

const linkTypeIcon = (type?: string) => {
  switch (type) {
    case 'facebook':
      return faFacebookF
    case 'twitter':
      return faTwitter
    case 'instagram':
      return faInstagram
    case 'linkedin':
      return faLinkedin
    default:
      return faLink
  }
}

interface Props {
  className?: string
  size?: string
  type?: string
  url: string
}

const LinkButton: React.FC<Props> = ({ className, size, type, url }) => (
  <a
    className={classNames('LinkButton', className, {
      [`LinkButton--${size}`]: size,
    })}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    title={type}
  >
    <FontAwesomeIcon icon={linkTypeIcon(type)} />
  </a>
)

export default LinkButton
