import React, { ReactNode } from 'react'
import './StepHeader.scss'

interface Props {
  children: ReactNode | ReactNode[]
}

const StepHeader: React.FC<Props> = ({ children }) => (
  <div className="StepHeader">{children}</div>
)

export default StepHeader
