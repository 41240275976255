import React, { useState } from 'react'
import useInterval from 'hooks/useInterval'

const Clock: React.FC = () => {
  const [date, setDate] = useState(new Date())

  useInterval(() => {
    setDate(new Date())
  }, 1000)

  return <>{date.toLocaleString()}</>
}

export default Clock
