import React from 'react'
import { CheckOrX } from 'types'
import CheckListItem from '../../atoms/CheckListItem'
import styles from './checkList.module.scss'

interface CheckListProps {
  items: CheckOrX[]
  title: string
}

const CheckList: React.FC<CheckListProps> = ({ items, title }) => {
  return (
    <section data-testid="checklist-section" className={styles.container}>
      <h3>{title}</h3>
      {items.map(item => (
        <CheckListItem key={item.field_name} item={item} />
      ))}
    </section>
  )
}

export default CheckList
