import React, { ComponentClass, FunctionComponent } from 'react'
import classNames from 'classnames'
import { MainDetailOption } from 'types'

interface Props {
  className?: string
  currentView: MainDetailOption
  detailComponent: ComponentClass | FunctionComponent | React.FC<any> | null
  isSmallScreen: boolean
  mainComponent: ComponentClass | FunctionComponent | React.FC<any>
}

export const MainDetail: React.FC<Props> = ({
  className,
  currentView = 'main',
  detailComponent,
  isSmallScreen,
  mainComponent,
}: Props) => {
  const showDetail =
    !isSmallScreen || (isSmallScreen && currentView === 'detail')
  const showMain = !isSmallScreen || (isSmallScreen && currentView === 'main')

  return (
    <div className={classNames('MainDetail', className)}>
      {showMain && React.createElement(mainComponent)}
      {showDetail && detailComponent && React.createElement(detailComponent)}
    </div>
  )
}

export default MainDetail
