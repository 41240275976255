import React from 'react'
import { useTranslation } from 'react-i18next'
import { Candidate, CandidateMarkdown } from 'types'
import { NewsItem } from '../../atoms'
import { findNewsLinkItems } from 'utils/newsMetadataUtils'
import styles from './newsItemList.module.scss'

interface NewsItemProps {
  candidate: Candidate
}

const NewsItemList: React.FC<NewsItemProps> = ({ candidate }) => {
  const { t } = useTranslation()
  const newsItems = findNewsLinkItems(candidate)
  if (newsItems.length < 1) {
    return null
  }
  return (
    <section className={styles.newsContainer} data-testid="news-section">
      <h2>{t('sparkles_main:CandidateDetails.newsTitle')}</h2>
      {newsItems.map((newsItem: CandidateMarkdown, index) => (
        <NewsItem newsItem={newsItem.metadata_blob} key={index} />
      ))}
    </section>
  )
}

export default NewsItemList
