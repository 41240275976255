import React from 'react'
import localforage from 'localforage'
import * as Sentry from '@sentry/react'
import { v4 as uuidv4 } from 'uuid'
import { detect } from 'detect-browser'
import qs from 'qs'
import analyticsContextContainer from './analyticsContextContainer'
import AnalyticsContext, {
  AnalyticsContextType,
  TrackFn,
} from './AnalyticsContext'
import {
  init as amplitude_init,
  track as amplitude_track,
} from '@amplitude/analytics-node'

const browser = detect()
;(window as any).NO_CS_SESSION_ALERT_COUNT = 0

type UtmParam =
  | 'utm_campaign'
  | 'utm_content'
  | 'utm_medium'
  | 'utm_source'
  | 'utm_term'

interface ClickstreamPayload {
  action: string
  label: string
  level: string
  properties: { [key: string]: any }
}

interface Props {
  ballotId: number | null
  children: React.ReactNode
  debug: boolean
  product: string
  tenantId: number
  sessionId?: string | null
}

class AnalyticsProvider extends React.Component<Props, AnalyticsContextType> {
  storage: LocalForage | null
  constructor(props: Props) {
    super(props)

    this.state = {
      browserName: browser?.name,
      browserOS: browser?.os,
      browserVersion: browser?.version,
      initialReferrer: document.referrer,
      initialUrl: document.location.href,
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
      sessionId: null,
      sessionExternallySet: !!props.sessionId,
      track: this.track,
      utm_campaign: null,
      utm_medium: null,
      utm_source: null,
      utm_content: null,
      utm_term: null,
      viewportHeight: window.innerHeight,
      viewportWidth: window.innerWidth,
    }
    this.storage = localforage.createInstance({
      name: 'clickstream',
    })

    const amplitude_project_id = process.env.REACT_APP_AMPLITUDE_PROJECT_KEY
    if (amplitude_project_id) {
      amplitude_init(amplitude_project_id)
    }

    this.createOrLoadSession()
  }

  async initializeStorage() {
    try {
      await this.storage?.setItem('initialized', true)
    } catch {
      this.storage = null
    }
  }

  /* this method sets the session token on this session
     along with the age of the session. after 30 mins, the session
     token gets cleared out and a new analytics session begins */
  async createOrLoadSession() {
    await this.initializeStorage()

    let sessionId = this.state.sessionExternallySet
      ? this.props.sessionId
      : await this.storage?.getItem<string>('sessionId')
    const currentTimestamp = Date.now()
    const maxSessionAge =
      process.env.REACT_APP_CLICKSTREAM_SESSION_TIMEOUT || 5400000
    let sessionLastSeenAt = await this.storage?.getItem<number>(
      'sessionLastSeenAt',
    )

    if (this.state.sessionExternallySet) {
      sessionLastSeenAt = currentTimestamp
    } else if (!sessionLastSeenAt || !sessionId) {
      sessionLastSeenAt = 0
    }

    const sessionAge = currentTimestamp - sessionLastSeenAt

    const forageUtmParam = async (param: UtmParam) =>
      this.storage?.getItem<string | null | undefined>(param)

    const ballotId = await this.storage?.getItem<number>('ballotId')

    let utm_campaign = await forageUtmParam('utm_campaign')
    let utm_content = await forageUtmParam('utm_content')
    let utm_medium = await forageUtmParam('utm_medium')
    let utm_source = await forageUtmParam('utm_source')
    let utm_term = await forageUtmParam('utm_term')

    let initialReferrer = await this.storage?.getItem<string | undefined>(
      'initial_referrer',
    )

    // handle session timeouts, after a specific period
    // ignore anything we get back from local storage and create
    // a new session id and blow out any stored utms

    if (sessionAge > maxSessionAge) {
      initialReferrer = null
      sessionId = uuidv4()
      utm_campaign = null
      utm_content = null
      utm_medium = null
      utm_source = null
      utm_term = null
    }

    // if we have utm parameters, then update and overwrite
    // anything that we might have set. if null then dont touch
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    if (params.utm_campaign) {
      utm_campaign = params.utm_campaign
    }
    if (params.utm_content) {
      utm_content = params.utm_content
    }
    if (params.utm_medium) {
      utm_medium = params.utm_medium
    }
    if (params.utm_source) {
      utm_source = params.utm_source
    }
    if (params.utm_term) {
      utm_term = params.utm_term
    }

    const newInitialReferrer = initialReferrer || this.state.initialReferrer
    await this.storage?.setItem('initial_referrer', newInitialReferrer)

    // if we have initial_referrer SET and DO NOT have any utms
    // and there ARE utms in the initial_referrer then set
    // those as our initial utms
    if (newInitialReferrer?.includes('utm_') && utm_source === null) {
      const rpms = new URLSearchParams(newInitialReferrer)
      if (rpms.get('utm_campaign')) {
        utm_campaign = rpms.get('utm_campaign')
      }
      if (rpms.get('utm_content')) {
        utm_content = rpms.get('utm_content')
      }
      if (rpms.get('utm_medium')) {
        utm_medium = rpms.get('utm_medium')
      }
      if (rpms.get('utm_source')) {
        utm_source = rpms.get('utm_source')
      }
      if (rpms.get('utm_term')) {
        utm_term = rpms.get('utm_term')
      }
    }

    await this.storage?.setItem('sessionId', sessionId)
    await this.storage?.setItem('sessionLastSeenAt', currentTimestamp)
    await this.storage?.setItem('utm_campaign', utm_campaign)
    await this.storage?.setItem('utm_content', utm_content)
    await this.storage?.setItem('utm_medium', utm_medium)
    await this.storage?.setItem('utm_source', utm_source)
    await this.storage?.setItem('utm_term', utm_term)
    if (ballotId) {
      await this.storage?.setItem('ballotId', ballotId)
    }
    this.setState({
      initialReferrer: newInitialReferrer,
      sessionId,
      utm_campaign,
      utm_content,
      utm_medium,
      utm_source,
      utm_term,
      sessionExternallySet: false,
    })
  }

  submit = async (payload: ClickstreamPayload) => {
    const debug = this.props.debug
    const url = process.env.REACT_APP_CLICKSTREAM_URL

    const enrichedPayload = Object.assign({}, payload, {
      ballot_id: this.props.ballotId,
      browser_name: this.state.browserName,
      browser_os: this.state.browserOS,
      browser_version: this.state.browserVersion,
      debug,
      initial_referrer: this.state.initialReferrer,
      initial_url: this.state.initialUrl,
      product: this.props.product,
      screen_height: this.state.screenHeight,
      screen_width: this.state.screenWidth,
      session_id: this.state.sessionId,
      tenant_id: this.props.tenantId,
      url: window.location.href,
      user_agent: navigator.userAgent,
      utm_campaign: this.state.utm_campaign,
      utm_content: this.state.utm_content,
      utm_medium: this.state.utm_medium,
      utm_source: this.state.utm_source,
      utm_term: this.state.utm_term,
      viewport_height: this.state.viewportHeight,
      viewport_width: this.state.viewportWidth,
    })

    if (debug) {
      console.log('[clickstream#submit]', enrichedPayload)
    }

    if (!url) {
      console.warn('[clickstream#submit] CLICKSTREAM_URL not set')
      return Promise.resolve()
    }

    // Record each event to Amplitude as well
    amplitude_track(payload.action, undefined, {
      // Use session_id as user_id as that or device_id is required
      // Also must be at least 5 characters
      user_id: this.state.sessionId!,
    })

    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(enrichedPayload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  }

  /* send helper function, which aggregates all the analytics providers
     and sends an event to all the destinations */
  track: TrackFn = async (action, properties = {}, label = null) => {
    const stringLabel = label ? String(label) : '' // coerce to string here or GA fatals

    // if we dont yet have a session id, retry again aggressvively
    if (this.state.sessionId === null) {
      setTimeout(this.track.bind(null, action, properties, label), 100)
      ;(window as any).NO_CS_SESSION_ALERT_COUNT += 1
      // pick numbers to alert on. This is to prevent infinite noise but to
      // also get a clear picture of whats up
      if (
        (window as any).NO_CS_SESSION_ALERT_COUNT === 200 ||
        (window as any).NO_CS_SESSION_ALERT_COUNT === 1000 ||
        (window as any).NO_CS_SESSION_ALERT_COUNT === 10000
      ) {
        Sentry.configureScope(scope => {
          scope.setExtra(
            'Attempt Number',
            (window as any).NO_CS_SESSION_ALERT_COUNT,
          )
          Sentry.captureMessage('No Clickstream Session Set')
        })
      }
      return Promise.resolve()
    }

    return this.submit({
      action,
      label: stringLabel,
      level: 'info',
      properties,
    })
  }

  render() {
    return (
      <AnalyticsContext.Provider value={this.state}>
        {this.props.children}
      </AnalyticsContext.Provider>
    )
  }
}

export default analyticsContextContainer(AnalyticsProvider)
