import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Position } from 'types'
import { OfficeCard } from 'components/sparkles'
import './PositionContainer.css'

interface Props {
  description: string
  positions: Position[]
  state: string
  title: string
}

const PositionContainer: React.FC<Props> = ({
  description,
  positions,
  state,
  title,
}) => {
  if (
    positions.findIndex(p => p.candidates && p.candidates.length > 0) === -1
  ) {
    return null
  }

  const naturalPositionsSortFn = (a: Position, b: Position) => {
    if (a.sub_area_value && b.sub_area_value) {
      return a.sub_area_value.localeCompare(b.sub_area_value, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    } else {
      return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    }
  }

  return (
    <div className="PositionContainer">
      <h2>{title}</h2>
      <ReactMarkdown source={description} />
      {positions.sort(naturalPositionsSortFn).map(p => (
        <OfficeCard key={p.position_id} position={p} state={state} />
      ))}
    </div>
  )
}

export default PositionContainer
