import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Trans, withTranslation } from 'react-i18next'
import { Button, Notification } from 'components/sparkles'
import authFormContainer from './authFormContainer'
import './AuthForm.css'

class AuthForm extends React.Component {
  static propTypes = {
    mode: PropTypes.string.isRequired,
    onForgotPasswordClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  state = {
    didSubmit: false,
    email: '',
    password: '',
    passwordConfirmation: '',
  }

  handleInputChange = e => {
    const { target } = e
    this.setState({ [target.name]: target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { mode } = this.props

    const { email, password, passwordConfirmation } = this.state

    if (mode === 'sign_up' && password !== passwordConfirmation) {
      console.log('Password did not match confirmation')
      return
    }

    this.setState({ didSubmit: true })
    this.props.onSubmit({ email, mode, password })
  }

  render() {
    const { mode, onForgotPasswordClick, t } = this.props

    const { didSubmit, email, password, passwordConfirmation } = this.state

    let submitBtnLabel = t('AuthForm.signIn')
    if (mode === 'sign_up') {
      submitBtnLabel = t('AuthForm.signUp')
    } else if (mode === 'reset_pw') {
      submitBtnLabel = t('AuthForm.resetPassword')
    }

    return (
      <form className="AuthForm" method="post" onSubmit={this.handleSubmit}>
        {mode === 'reset_pw' && didSubmit && (
          <Notification variant="success">
            <Trans i18nKey="authFormResetPasswordInstructions">
              An email with instructions on how to reset your password was sent
              to <strong>{{ email }}</strong>.
            </Trans>
          </Notification>
        )}
        <input
          className="FormControl"
          disabled={mode === 'reset_pw' && didSubmit}
          name="email"
          onChange={this.handleInputChange}
          placeholder={t('AuthForm.placeholderEmail')}
          required
          type="email"
          value={email}
        />
        {mode !== 'reset_pw' && (
          <input
            className="FormControl"
            name="password"
            onChange={this.handleInputChange}
            placeholder={t('AuthForm.placeholderPassword')}
            required
            type="password"
            value={password}
          />
        )}
        {mode === 'sign_in' && (
          <Button
            onClick={onForgotPasswordClick}
            size="sm"
            type="button"
            variant="link"
          >
            {t('AuthForm.forgotPasswordQuestion')}
          </Button>
        )}
        {mode === 'sign_up' && (
          <input
            className="FormControl"
            name="passwordConfirmation"
            onChange={this.handleInputChange}
            placeholder={t('AuthForm.placeholderPasswordConfirmation')}
            required
            type="password"
            value={passwordConfirmation}
          />
        )}

        <Button
          block
          className="AuthForm__Submit"
          data-testid="btn-sign-in"
          disabled={mode === 'reset_pw' && didSubmit}
          size="lg"
          type="submit"
          variant="primary"
        >
          {submitBtnLabel}
        </Button>
      </form>
    )
  }
}

export default withTranslation()(withRouter(authFormContainer(AuthForm)))
