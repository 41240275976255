// The PA API provides key/value pairs each option,
// but the api actually expects the part that is the description,
// so we can use a simple array for the dropdowns

import { OptionType } from '../types'

// and have the displayed value and the value be the same.
export function mapDescriptionFieldsToSimpleList(
  fieldName: string,
  values: any[],
): string[] {
  const list: any[] = []
  if (values) {
    values.forEach((value: any) => {
      if (value[fieldName]) {
        list.push(value[fieldName])
      }
    })
  }
  return list
}

export function mapDescriptionFieldsToSelectOptions(
  valueFieldName: string,
  labelFieldName: string,
  values: any[],
): OptionType[] {
  const list: OptionType[] = []
  if (values) {
    values.forEach((value: any) => {
      if (value[valueFieldName] && value[labelFieldName]) {
        list.push({
          value: value[valueFieldName],
          label: value[labelFieldName],
        })
      }
    })
  }
  return list
}

export function convertNamesToSelectOption(names: string[]) {
  return names.map((name: string) => {
    return { value: name, label: name }
  })
}
