import React from 'react'
import { useTranslation } from 'react-i18next'
import { Candidate, CandidateUrl } from 'types'
import { Button } from 'components/sparkles'
import styles from './CandidateSupportButtons.module.scss'
import getPathForApp from 'utils/getPathForApp'

export interface Props {
  candidate: Candidate
  linkToCandidate?: boolean
  urls: CandidateUrl[]
}

const CandidateSupportButtons: React.FC<Props> = ({
  candidate,
  linkToCandidate = false,
  urls,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.buttonGroup}>
      {linkToCandidate && (
        <Button
          to={`${getPathForApp('super_slate')}candidates/${candidate.id}`}
          variant="primary"
        >
          {t('super_slate:CandidateSupportButtons.learnMore')}
        </Button>
      )}
      {urls.map((url, key) => {
        return (
          <Button
            className={styles.button}
            href={url.url}
            variant="default"
            key={key}
          >
            {t(`super_slate:CandidateSupportButtons.${url.type}`)}
          </Button>
        )
      })}
    </div>
  )
}

export default CandidateSupportButtons
