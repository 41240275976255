import { Button, Markdown } from '../../atoms'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface TextReadMoreProps {
  className?: string
  cutoff?: number
  text: string
}

const TextReadMore: React.FC<TextReadMoreProps> = ({
  className,
  cutoff = 200,
  text,
}) => {
  const [showFullText, setShowFullText] = useState(false)
  const { t } = useTranslation()

  const useTruncation = text?.length > cutoff

  if (!text) {
    return null
  }

  const truncatedText = getTruncatedText(text, cutoff)

  return (
    <div data-cy="textreadmore" className={className}>
      <Markdown>
        {showFullText || !useTruncation ? text : truncatedText}
      </Markdown>
      {useTruncation && truncatedText.length !== text.length && (
        <Button
          data-cy="textreadmore-toggle"
          variant="link"
          onClick={() => setShowFullText(!showFullText)}
        >
          {showFullText
            ? t('sparkles_main:TextReadMore.readLess')
            : t('sparkles_main:TextReadMore.readMore')}
        </Button>
      )}
    </div>
  )
}

function getTruncatedText(text: string, cutoff: number) {
  let newLength = cutoff
  while (
    text[newLength] !== '.' &&
    text[newLength] !== '?' &&
    text[newLength] !== '!' &&
    newLength < text.length
  ) {
    newLength++
  }

  return text.slice(0, newLength + 1)
}

export default TextReadMore
