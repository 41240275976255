import React, { useCallback } from 'react'
import { FieldProps } from 'formik'
import Select from 'react-select'
import { OptionType } from 'types'

const SelectField: React.FC<
  FieldProps & {
    customOnChange?(option: string): void
    options: OptionType[]
  }
> = ({
  customOnChange,
  field, // { name, value, onChange, onBlur }
  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const handleChange = useCallback(
    option => {
      customOnChange?.(option.value)
      setFieldValue(field.name, option.value)
    },
    [customOnChange, field.name, setFieldValue],
  )

  const { options } = props
  const value = options
    ? options.find(option => (option as OptionType).value === field.value)
    : undefined

  return (
    <Select
      {...field}
      {...props}
      key={`${field.name}__${value}`} // Forces a remount when value changes. Just setting value=null doesn't seem to reset anything
      options={options}
      value={value}
      onChange={handleChange}
    />
  )
}
export default SelectField
