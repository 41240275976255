import { SAVED_LEAD } from 'actions/constants'

export default (state = null, action) => {
  switch (action.type) {
    case SAVED_LEAD:
      return action.payload
    default:
      return state
  }
}
