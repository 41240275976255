import { MarkdownBlob } from 'types'
import { sliceLocale } from './sliceLocale'

const defaultLocale = 'en'

export default (
  blobs: MarkdownBlob[],
  type: string,
  locale: string = defaultLocale,
) => {
  const blobsForType = blobs.filter(
    b => b.markdown_type.toLowerCase() === type.toLowerCase(),
  )

  // Let's see if a blob with requested locale exists. If it doesn't we'll fall
  // back to English
  const cleanedLocale = sliceLocale(locale)
  const hasBlobInRequestedLocale =
    blobsForType.findIndex(b => b.locale === cleanedLocale) !== -1
  const useLocale = hasBlobInRequestedLocale ? cleanedLocale : defaultLocale
  const blob = blobsForType.find(b => b.locale === useLocale)

  return blob ? blob.markdown_blob : null
}
