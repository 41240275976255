import React from 'react'
import { DirectoryHero, PositionContainer } from 'components/sparkles'
import { UsState } from 'types'

export interface Props {
  positions: any[]
  state: UsState
}

const DirectoryPositionsList: React.FC<Props> = ({ positions, state }) => {
  return (
    <>
      <DirectoryHero />
      {positions && positions.length > 0 && (
        <section>
          {positions.map((position, key) => (
            <PositionContainer
              description={position[1][0].description}
              key={key}
              positions={position[1]}
              state={state.short_name.toLowerCase()}
              title={position[0]}
            />
          ))}
        </section>
      )}
    </>
  )
}

export default DirectoryPositionsList
