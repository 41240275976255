import * as types from '../actions/constants'

export default (state = [], action) => {
  switch (action.type) {
    case types.FILTER_TAG:
      if (action.checked) {
        return [...new Set([action.tag, ...state])]
      } else {
        const i = state.indexOf(action.tag)
        return [...state.slice(0, i), ...state.slice(i + 1)]
      }
    default:
      return state
  }
}
