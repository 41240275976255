import { AnyAction } from 'redux'
import * as types from 'actions/constants'

const initialState = {
  hasReceivedMeasures: false,
  hasReceivedPositions: false,
}

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.RECEIVE_MEASURES:
      return Object.assign({}, state, {
        hasReceivedMeasures: true,
      })
    case types.RECEIVE_POSITIONS:
      return Object.assign({}, state, {
        hasReceivedPositions: true,
      })
    case types.REQUEST_BALLOT:
      return initialState
    default:
      return state
  }
}
