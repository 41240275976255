import { Degree, Education } from 'types'
// Current list of known degree types.
//
// https://app.asana.com/0/904059506981495/773494810366185/f
const degreeOrder = [
  'Doctorate',
  'MD',
  'JD',
  'MBA',
  "Master's",
  "Bachelor's",
  "Associate's",
  'Licensure',
  'Certificate',
  'High School Diploma',
  'GED',
  null, // list education with unknown degree type last
]

export default (a: Education, b: Education) =>
  degreeOrder.indexOf(a.degree as Degree) -
  degreeOrder.indexOf(b.degree as Degree)
