import * as types from 'actions/constants'

export default (state = [], action) => {
  switch (action.type) {
    case types.RECEIVE_ENDORSED_CANDIDATES:
      return action.payload
    default:
      return state
  }
}
