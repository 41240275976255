import * as types from '../actions/constants'

const reminderChannels = (
  state = {
    email: { isValid: null, value: null },
    phone: { isValid: null, value: null },
  },
  action,
) => {
  switch (action.type) {
    case types.ADD_REMINDER_CHANNEL:
      return Object.assign({}, state, action.channel)
    default:
      return state
  }
}

export default reminderChannels
