import classNames from 'classnames'
import React, { ReactNode } from 'react'
import styles from './hexagon.module.scss'

interface HexagonProps {
  children: ReactNode
  className?: string
}

const Hexagon: React.FC<HexagonProps> = ({ children, className }) => (
  <div data-testid="hexagon" className={classNames(styles.hexagon, className)}>
    <div className={classNames(styles.trapezoid, 'trapezoid__left')} />
    <div className={classNames(styles.trapezoid, 'trapezoid__right')} />
    <div className={styles.body}>{children}</div>
  </div>
)

export default Hexagon
