import React from 'react'
import { Formik, Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { Ballot } from 'types'

import { Button, Markdown } from 'components/sparkles'
import styles from './EmailAndPhoneCapture.module.scss'

interface Props {
  ballot: Ballot
  buttonText: string
  onSubmit(values: any): void
}

const EmailAndPhoneCapture = React.memo(
  ({ ballot, buttonText, onSubmit }: Props) => {
    const { t } = useTranslation()

    return (
      <Formik
        initialValues={{
          email: ballot?.email || '',
          phone: ballot?.phone || '',
        }}
        onSubmit={(values: any) => onSubmit(values)}
      >
        {({ handleSubmit }) => (
          <form className={styles.formInner} onSubmit={handleSubmit}>
            <label className={styles.label} id="email_label">
              {t('request_ballot:EmailStep.email')}
            </label>
            <Field
              aria-labelledby="email_label"
              className={styles.input}
              name="email"
              placeholder={t('request_ballot:EmailStep.emailPlaceholder')}
              type="email"
              required
            />
            <label className={styles.label}>
              {t('request_ballot:EmailStep.phone')}
            </label>
            <Field
              className={styles.input}
              name="phone"
              placeholder={t('request_ballot:EmailStep.phonePlaceholder')}
              type="phone"
            />
            <Markdown source={t('request_ballot:EmailStep.disclaimer')} />
            <Button
              className={styles.button}
              data-testid="next-button"
              padded
              size="lg"
              type="submit"
              variant="danger"
            >
              {buttonText}
            </Button>
          </form>
        )}
      </Formik>
    )
  },
)

export default EmailAndPhoneCapture
