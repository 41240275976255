import * as types from '../actions/constants'

export default (state = 0, { type }) => {
  switch (type) {
    case types.PING_BALLOT:
      return state + 1
    case types.PING_BALLOT_STOP:
      return 0
    default:
      return state
  }
}
