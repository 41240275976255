import React from 'react'
import { CheckIcon, Panel } from '../../atoms'
import styles from './flowButton.module.scss'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

interface Props {
  checked: boolean
  className?: string
  classNameInner?: string
  href?: string
  onClick?(value: boolean | string | undefined): void
  testTag?: string
  value: boolean | string | undefined
  to?: string
}

const FlowButton: React.FC<Props> = ({
  checked,
  children,
  className,
  classNameInner,
  href,
  onClick,
  testTag,
  value,
  to,
}) => {
  return (
    <Panel
      className={classNames(styles.root, className)}
      isButton
      onClick={() => onClick && onClick(value)}
    >
      {to && (
        <Link
          className={classNames(styles.inner, styles.link, classNameInner)}
          to={to}
        >
          <CheckIcon checked={checked} className={styles.icon} />
          {children}
        </Link>
      )}
      {href && (
        <a
          className={classNames(styles.inner, styles.link, classNameInner)}
          href={href}
          rel="noopener noreferrer"
        >
          <CheckIcon checked={checked} className={styles.icon} />
          {children}
        </a>
      )}
      {!to && !href && (
        <div
          className={classNames(styles.inner, classNameInner)}
          data-cy={testTag}
        >
          <CheckIcon checked={checked} className={styles.icon} />
          {children}
        </div>
      )}
    </Panel>
  )
}

export default FlowButton
