import React from 'react'
import classNames from 'classnames'
import styles from './Step.module.scss'

interface Props {
  className?: string
  narrow?: boolean
}

const Step: React.FC<Props> = ({ children, className, narrow = false }) => (
  <div
    className={classNames(
      styles.root,
      {
        [styles.narrow]: narrow,
      },
      className,
    )}
  >
    {children}
  </div>
)

export default Step
