import { connect } from 'react-redux'

import toggleLeadBar from 'actions/toggleLeadBar'
import toggleShareableBallotBanner from 'actions/toggleShareableBallotBanner'

const mapStateToProps = ({
  appConfig: {
    authEnabled,
    customButton,
    enableMaptv,
    enableVoterRegistration,
    featureFlags,
    logoUrl,
    enableElectionCenter,
    enableLeadBar,
    enableShareableBallots,
    tenant: { name },
    universe: { logoLinkUrl },
  },
  auth,
  ballot,
  browser,
  election,
  lead,
  showLeadBar,
  showShareableBallotBanner,
}) => ({
  auth,
  authEnabled,
  ballot,
  customButton,
  election,
  enableMaptv,
  enableSearch: featureFlags.includes('ELECTION_CENTER_SEARCH'),
  enableVoterRegistration,
  isSmallScreen: browser.lessThan.medium,
  lead,
  logoLinkUrl,
  logoUrl,
  showECNavigation: enableElectionCenter,
  showLeadBar: enableLeadBar && showLeadBar && ballot,
  showShareableBallotBanner:
    enableShareableBallots && showShareableBallotBanner && auth.isSignedIn,
  tenantName: name,
})

const mapDispatchToProps = dispatch => ({
  onLeadBarClose: () => dispatch(toggleLeadBar(false)),
  onShareableBallotBannerClose: () =>
    dispatch(toggleShareableBallotBanner(false)),
})

export default connect(mapStateToProps, mapDispatchToProps)
