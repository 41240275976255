import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Button, ListItems } from 'components/sparkles'
import { Item } from 'types'
import styles from './SearchDropdown.module.scss'
import { SavedSearch } from 'appConfig'
import useAnalytics from 'hooks/useAnalytics'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'

interface Props {
  close(event?: React.MouseEvent): void
  isSmallScreen: boolean
  savedSearches: SavedSearch[]
  search(searchTerm: string): void
  searching: boolean
  searchResults: any[]
  searchTerm: string
  setSearchInitiated(value: boolean): void
}

const SearchDropdown = ({
  close,
  isSmallScreen,
  savedSearches,
  search,
  searching,
  searchResults,
  searchTerm,
  setSearchInitiated,
}: Props) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState('')
  const analytics = useAnalytics()

  const dropdownRef = useRef() as any
  const handleClickOutside = (e: any) => {
    const ref = dropdownRef.current
    if (ref && !ref.contains(e.target)) {
      setSearchInitiated(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  const onSavedSearchClick = (savedSearch: SavedSearch) => {
    setSelected(savedSearch.label)
    search(savedSearch.search)
    analytics?.track('Saved search clicked', {
      label: savedSearch.label,
      search: savedSearch.search,
    })
  }

  const formatResults = (searchResults: any) => {
    const results: Item[] = []
    searchResults.forEach((result: any) => {
      let resultType = 'candidacy'

      if (result['index']) {
        resultType = result['index'].startsWith('candidacies')
          ? 'candidacy'
          : 'position'
      }

      if (resultType === 'candidacy') {
        results.push({
          id: result.candidate_id,
          linkTarget: `/search/positions/${result.position_id}/candidates/${result.candidate_id}`,
          name: result.candidate_name,
          person: result,
          showHeadshot: true,
          showInitial: false,
        })
      } else if (resultType === 'position') {
        results.push({
          id: result.position_id,
          linkTarget: `/search/positions/${result.position_id}`,
          name: result.position_name,
          showHeadshot: false,
          showInitial: true,
        })
      }
    })
    return results
  }

  return (
    <div
      className={classNames(
        styles.dropdown,
        searchResults.length === 0 && styles.collapsed,
        isSmallScreen && styles.mobile,
      )}
      ref={dropdownRef}
    >
      <span className={styles.suggestion}>
        {t('search:SearchApp.trySearchingFor')}
      </span>

      <div className={styles.savedContainer}>
        {savedSearches.map(savedSearch => (
          <Button
            className={styles.savedSearchTag}
            icon={faSearch}
            key={savedSearch.label}
            square
            outline={!(savedSearch.label === selected)}
            onClick={() => onSavedSearchClick(savedSearch)}
            size="sm"
            variant="primary"
          >
            {savedSearch.label}
          </Button>
        ))}
      </div>
      {searchResults.length > 0 && (
        <>
          <p className={styles.resultsHeader}>{t('common:results')}</p>
          <ListItems
            onClick={(event: React.MouseEvent) => {
              // TODO: add more information on which result was selected
              // dependent on adding clickstream functionality to nested
              // the current selected field is not that helpful
              analytics?.track('Search result selected', {
                search: searchTerm,
                selected: event.currentTarget.textContent,
              })
              close(event)
            }}
            listItems={formatResults(searchResults)}
          />
        </>
      )}
      {searchTerm && !searching && searchResults.length === 0 && (
        <p>{t('search:SearchApp.noResults')}</p>
      )}
    </div>
  )
}

export default SearchDropdown
