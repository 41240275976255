import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Candidate, Selection, Position } from 'types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { Headshot, PartyInitials, Tooltip } from '../../atoms'
import nameFormatter from 'utils/nameFormatter'
import styles from './candidacyListItem.module.scss'

export interface CandidacyListItemProps {
  active: boolean
  badge?: string
  candidate: Candidate
  className?: string
  electionId?: number | null
  enableTooltip?: boolean
  featured?: boolean
  isPrimary?: boolean
  inDirectory?: boolean
  linkTarget?: string
  onClick?(
    event: React.MouseEvent | React.KeyboardEvent,
    candidate?: Candidate,
    position?: Position,
  ): void
  position: Position
  selection?: Selection
  shouldRedirect?: boolean
  small?: boolean
  withdrawn?: boolean
}

const CandidacyListItem: React.FC<CandidacyListItemProps> = ({
  active,
  badge = 'Recommended',
  candidate,
  className,
  electionId,
  enableTooltip = false,
  featured,
  linkTarget,
  onClick,
  position,
  selection,
  shouldRedirect = false,
  small = false,
  withdrawn,
}) => {
  const shouldShowParty = position?.partisan_type !== 'nonpartisan'
  const parties = electionId
    ? candidate.parties.filter(party => party.election_id === electionId)
    : candidate.parties

  const handleClick = (event: React.MouseEvent | React.KeyboardEvent) => {
    const id = candidate.id || candidate.candidate_id
    if (onClick && id) {
      onClick(event, candidate, position)
    }
  }

  const bodyContent = (
    <div
      className={classNames(
        styles.root,
        active && styles.active,
        withdrawn && styles.withdrawn,
        className,
      )}
      data-cy="candidacylistitem"
      data-testid={nameFormatter(candidate)}
      id={(candidate.id || candidate.candidate_id)?.toString()}
      onClick={handleClick}
      onKeyPress={handleClick}
      role="button"
      tabIndex={0}
      aria-labelledby={'candidate-label-' + candidate.id}
    >
      {featured && (
        <div className={styles.recommended}>
          <FontAwesomeIcon icon={faStar} /> {badge}
        </div>
      )}
      {enableTooltip ? (
        <Tooltip
          text={`${candidate.first_name} ${
            candidate.nickname ? '' + candidate.nickname + ' ' : ''
          }
             ${candidate.last_name}`}
        >
          <div
            className={styles.headshot}
            data-testid={`headshot--${candidate.id}`}
          >
            <Headshot active={active} border={small} person={candidate} />
            {selection && (
              <span
                className={classNames(
                  'fa-layers',
                  'fa-fw',
                  styles.headshot__saved,
                )}
              >
                {selection.rank ? (
                  <div className={styles.saved__rank}>{selection.rank}</div>
                ) : (
                  <FontAwesomeIcon icon={faCheck} title="Saved" />
                )}
              </span>
            )}
          </div>
        </Tooltip>
      ) : (
        <div className={styles.headshot}>
          <Headshot active={active} border={small} person={candidate} />
          {selection && (
            <span
              className={classNames(
                'fa-layers',
                'fa-fw',
                styles.headshot__saved,
              )}
            >
              {selection.rank ? (
                <div className={styles.saved__rank}>{selection.rank}</div>
              ) : (
                <FontAwesomeIcon icon={faCheck} title="Saved" />
              )}
            </span>
          )}
        </div>
      )}
      <div className={styles.details} id={'candidate-label-' + candidate.id}>
        {candidate.first_name}
        {candidate.nickname && ` "${candidate.nickname}"`}
        <br />
        {candidate.last_name}{' '}
        {shouldShowParty && <PartyInitials parties={parties} />}
      </div>
    </div>
  )

  if (shouldRedirect && !!linkTarget) {
    return (
      <Link
        className={classNames(styles.link, small && styles.small)}
        to={linkTarget}
        onClick={(event: React.MouseEvent | React.KeyboardEvent) =>
          handleClick(event)
        }
      >
        {bodyContent}
      </Link>
    )
  }

  return (
    <div className={classNames(styles.link, small && styles.small)}>
      {bodyContent}
    </div>
  )
}

export default CandidacyListItem
