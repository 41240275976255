import * as types from '../actions/constants'

export default (
  state = {
    request_ballot: [],
    check_registration: [],
    m: [], // following /m route
  },
  action,
) => {
  switch (action.type) {
    case types.PUSH_STEPS:
      return {
        ...state,
        [action.source]: action.steps,
      }
    default:
      return state
  }
}
