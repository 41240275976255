import React from 'react'
import classNames from 'classnames'
import { CheckIcon } from 'components/sparkles'
import ExcuseExpander from './ExcuseExpander/ExcuseExpander'

import styles from './ExcuseButton.module.scss'
import { Excuse } from 'types'

interface Props {
  excuse: Excuse // this might not work for other places?
  isSelectedExcuse: boolean
  onExcuseClick(key: string): void
}

const ExcuseButton = ({ excuse, isSelectedExcuse, onExcuseClick }: Props) => {
  return (
    <button
      className={classNames(styles.excuseBtn, {
        [`${styles.selectedExcuse}`]: isSelectedExcuse,
      })}
      key={excuse.key}
      onClick={() => onExcuseClick(excuse.key)}
    >
      <CheckIcon className={styles.excuseBtnIcon} checked={isSelectedExcuse} />
      <ExcuseExpander excuse={excuse.full_text} />
    </button>
  )
}

export default ExcuseButton
