import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt'
import AddressForm from 'components/AddressForm/AddressForm'
import { Modal } from 'components/sparkles'
import './AddressModal.scss'
import { Ballot } from 'types'

interface Props {
  ballot?: Ballot
  shouldRedirect?: boolean
  onUpdateAddress?: () => void
  onClose: (isSubmit: boolean) => void
  modalTitle?: string
}

const AddressModal: React.FC<Props> = ({
  ballot,
  shouldRedirect = true,
  onUpdateAddress,
  onClose,
  modalTitle,
}) => {
  const { t } = useTranslation()

  const handleSubmit = () => {
    onUpdateAddress && onUpdateAddress()
    onClose(true)
  }

  return (
    <Modal
      appElement={document.getElementById('root')}
      isOpen={true}
      onClose={() => onClose(false)}
      modalTitle={modalTitle}
      bodyClassName="Modal__Body"
    >
      {ballot ? (
        <div className="AddressModal__CurrentAddress">
          <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
          {t('AddressModal.currentAddress')}:<strong>{ballot.address}</strong>
          <div className="AddressModal_changeAddressExplanation">
            {t('AddressModal.changeAddressExplanation')}
          </div>
        </div>
      ) : (
        <>
          <h2>{t('AddressModal.updateAddress')}</h2>
          {t('AddressModal.explanation')}
        </>
      )}

      <AddressForm
        onCloseModal={ballot && (() => onClose(false))} // can't have option to keep address if there's no address currently
        onSubmit={handleSubmit}
        showLabels={true}
        redirect={shouldRedirect}
        buttonText={ballot && t('AddressModal.submitButton')}
      />
    </Modal>
  )
}

export default AddressModal
