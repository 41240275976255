import React from 'react'
import classNames from 'classnames'
import { enterKeyPress } from 'utils/enterKeyPress'
import styles from './panel.module.scss'

interface PanelProps {
  children: React.ReactNode
  className?: string
  isButton?: boolean
  onClick?(): void
}

const Panel: React.FC<PanelProps> = ({
  children,
  className,
  isButton,
  onClick,
}: PanelProps) => (
  <div
    className={classNames(styles.panel, isButton && styles.button, className)}
    data-testid="panel"
    onClick={onClick}
    onKeyPress={(e: React.KeyboardEvent) => {
      if (enterKeyPress(e)) {
        onClick && onClick()
      }
    }}
    role={isButton ? 'button' : undefined}
    tabIndex={0}
  >
    {children}
  </div>
)

export default Panel
