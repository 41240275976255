import React from 'react'
import { MeasureShareCard, PositionShareCard } from 'components/sparkles'
import SharedBallotHeader from './SharedBallotHeader/SharedBallotHeader'
import './SharedBallot.scss'

const SharedBallot = ({
  user,
  groupedPositions,
  isReadOnly,
  measures,
  onCommentSubmit,
  onUserSubmit,
}) => (
  <div className="SharedBallot">
    <SharedBallotHeader
      bio={user && user.bio}
      editable={onUserSubmit && !isReadOnly}
      name={user && user.name}
      onSubmit={onUserSubmit}
    />

    {groupedPositions.map(
      ({ items, title }, key) =>
        items.length > 0 && (
          <section className="SharedBallot__Section" key={key}>
            <h2>{title}</h2>
            {items.map(position => (
              <PositionShareCard
                isReadOnly={isReadOnly}
                key={position.id}
                name={user && user.name}
                onCommentSubmit={onCommentSubmit}
                position={position}
              />
            ))}
          </section>
        ),
    )}
    {measures && measures.length > 0 && (
      <div>
        <h2>Measures</h2>
        {measures.map(measure => (
          <MeasureShareCard
            isReadOnly={isReadOnly}
            key={measure.id}
            measure={measure}
            name={user && user.name}
            onCommentSubmit={onCommentSubmit}
          />
        ))}
      </div>
    )}
  </div>
)

export default SharedBallot
