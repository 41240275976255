import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { Markdown } from 'components/sparkles'
import styles from './Alert.module.scss'

export interface Props {
  children: React.ReactNode
  dismissable?: boolean
  onClose?(): void
}

const Alert: React.FC<Props> = ({ children, dismissable, onClose }: Props) => {
  return (
    <div className={styles.alert}>
      <Markdown>{children}</Markdown>
      {dismissable && (
        <button className={styles.close} onClick={onClose} title="Hide alert">
          <FontAwesomeIcon icon={faTimes} size="2x" />
        </button>
      )}
    </div>
  )
}

export default Alert
