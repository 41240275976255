import React from 'react'
import './DistrictList.css'

interface Props {
  districts: { title: string; value: string }[]
}

const DistrictList: React.FC<Props> = ({ districts }) => (
  <div className="DistrictList">
    {districts.map((district, key) => (
      <div className="DistrictListItem" key={key}>
        {district.title}
        <h6>{district.value}</h6>
      </div>
    ))}
  </div>
)

export default DistrictList
