import React from 'react'
import { Party } from 'types'
import styles from './PartyCircle.module.scss'

export interface Props {
  party: Party
}

const PartyCircle: React.FC<Props> = ({ party }) => (
  <span
    className={styles.root}
    style={{ backgroundColor: party.color_primary }}
  >
    {party.short_name}
  </span>
)

export default PartyCircle
