import React, { ReactNode } from 'react'
import classNames from 'classnames'
import './ShareCard.scss'

interface Props {
  children: ReactNode | ReactNode[]
  className?: string
  title?: string
}

const ShareCard: React.FC<Props> = ({ children, className, title }) => (
  <div className={classNames('ShareCard', className)}>
    {title && <h3>{title}</h3>}
    {children}
  </div>
)

export default ShareCard
