import * as types from 'actions/constants'

export default (state = null, { type, payload }) => {
  switch (type) {
    case types.SAVED_VOTING_PLAN:
    case types.RECEIVE_VOTING_PLAN:
      if (!payload || Object.entries(payload).length === 0) {
        return null
      }
      return Object.assign({}, state, payload)
    default:
      return state
  }
}
