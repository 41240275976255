import * as types from 'actions/constants'
import apiFetch from './apiFetch'

const requestedPasswordReset = email => ({
  type: types.REQUESTED_PASSWORD_RESET,
  email,
})

const requestingPasswordReset = () => ({
  type: types.REQUESTING_PASSWORD_RESET,
})

export default email => async (dispatch, getState) => {
  dispatch(requestingPasswordReset())

  const {
    appConfig: { tenant },
  } = getState()

  const response = await dispatch(
    apiFetch('/ballot/login/reset', {
      method: 'POST',
      body: JSON.stringify({
        email,
        tenant_id: tenant.id,
      }),
    }),
  )

  const json = await response.json()

  if (json) {
    return dispatch(requestedPasswordReset(email))
  }
}
