import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown } from '@fortawesome/pro-solid-svg-icons/faThumbsDown'
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons/faThumbsUp'
import { Headshot, SelectionComment, ShareCard } from 'components/sparkles'
import './MeasureShareCard.css'

class MeasureShareCard extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    isReadOnly: PropTypes.bool,
    measure: PropTypes.object.isRequired,
    onCommentSubmit: PropTypes.func,
  }

  static defaultProps = {
    isReadOnly: true,
  }

  render() {
    const { isReadOnly, name, onCommentSubmit, measure } = this.props

    const selection = measure.selection

    return (
      <ShareCard className="MeasureShareCard" title={measure.name}>
        <div className="MeasureShareCard__Selection">
          {(measure.name || measure.title) && measure.image_url && (
            <Headshot
              alt={measure.name || measure.title}
              imageUrl={measure.image_url}
            />
          )}
          <h4>Vote {selection.yes_no === 'yes' ? 'Yes' : 'No'}</h4>
          {selection.yes_no && (
            <FontAwesomeIcon
              className={classNames('MeasureShareCard__Icon', {
                'MeasureShareCard__Icon--danger': selection.yes_no === 'no',
                'MeasureShareCard__Icon--success': selection.yes_no === 'yes',
              })}
              icon={selection.yes_no === 'yes' ? faThumbsUp : faThumbsDown}
            />
          )}
        </div>
        <SelectionComment
          author={name ? name : undefined}
          editable={onCommentSubmit && !isReadOnly}
          onSubmit={onCommentSubmit}
          selection={selection}
        />
      </ShareCard>
    )
  }
}

export default MeasureShareCard
