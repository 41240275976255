import React from 'react'
import { FlowButton } from '../../molecules'

import styles from './flowChoice.module.scss'

interface FlowChoiceProps<T> {
  onSelect?(value: T): void
  options: FlowOption<T>[]
  selected?: string | boolean
}

type FlowOption<T> = {
  text: string
  value: T
  href?: string
  testTag?: string
}

type ValueType = string | boolean | undefined

const FlowChoice = <T extends ValueType>({
  onSelect,
  options,
  selected,
}: FlowChoiceProps<T>) => {
  const optionList = options.map((option: FlowOption<T>, i: number) => (
    <FlowButton
      checked={selected === option.value}
      href={option.href}
      key={i}
      onClick={option.href ? undefined : onSelect}
      value={option.value}
      testTag={option.testTag}
    >
      {option.text}
    </FlowButton>
  ))

  return (
    <div className={styles.flowChoice} data-testid="flowchoice">
      {optionList}
    </div>
  )
}

export default FlowChoice
