import React from 'react'
import { InformationPanel } from 'components/sparkles'
import { ReactComponent as Tracking } from 'assets/images/tracking.svg'
import { useTranslation } from 'react-i18next'
import trackingInformationPanelContainer from './trackingInformationPanelContainer'
import styles from './TrackingInformationPanel.module.scss'

interface Props {
  tracking: string | null
}

const TrackingInformationPanel: React.FC<Props> = ({ tracking }) => {
  const { t } = useTranslation()

  if (!tracking) {
    return null
  }
  return (
    <InformationPanel
      className={styles.trackingPanel}
      icon={Tracking}
      title={t('request_ballot:NextStepsScene.trackYourBallot')}
      text={t('request_ballot:NextStepsScene.trackItHere', { link: tracking })}
    />
  )
}

export default trackingInformationPanelContainer(TrackingInformationPanel)
