import classNames from 'classnames'
import { ListItems, SubNav } from '../../organisms'
import React from 'react'
import styles from './resultView.module.scss'
import { Item } from 'types'

type NavOption = { key: string | number; label: string }

interface ResultViewProps {
  borders?: boolean
  categoryFilters?: {
    [key: string]: (value: Item) => boolean
  }
  getSelectedItem(event: React.MouseEvent): void
  isSmallScreen: boolean
  listItems: Item[] // todo
  navOptions?: NavOption[]
  onClickNav?(navSelection: NavOption): void
  renderBodyContent(): JSX.Element
  renderEmptyState(noneAvailable: boolean): JSX.Element
  selectedItemExists: boolean
  selectedNavItem?: string
  selectedItemId?: string
}

const ResultView: React.FC<ResultViewProps> = ({
  borders,
  categoryFilters,
  getSelectedItem,
  isSmallScreen,
  listItems,
  navOptions,
  onClickNav,
  selectedNavItem,
  renderBodyContent,
  renderEmptyState,
  selectedItemExists,
  selectedItemId,
}) => {
  const meetYourRep = listItems?.some(i => !!i.description)

  return (
    <>
      {navOptions && (
        <SubNav
          onClick={onClickNav}
          selectedId={selectedNavItem}
          options={navOptions}
        />
      )}
      <div
        aria-hidden={selectedItemExists && isSmallScreen}
        className={classNames(
          styles.container,
          isSmallScreen &&
            (selectedItemExists ? styles.hideResults : styles.hideListItems),
          meetYourRep && styles.meetYourRepListContainer,
        )}
      >
        <div
          className={classNames(
            styles.listView,
            listItems.length === 0 && styles.hidden,
          )}
          tabIndex={-1}
        >
          {categoryFilters ? (
            Object.entries(categoryFilters).map(([key, categoryFilter]) => {
              return (
                !!listItems.filter(item => categoryFilter(item)).length && (
                  <div key={key}>
                    <div className={styles.categoryFilter}>{key}</div>
                    <ListItems
                      borders={!meetYourRep && borders}
                      listItems={listItems.filter(item => categoryFilter(item))}
                      onClick={(event: React.MouseEvent) =>
                        getSelectedItem(event)
                      }
                      selectedId={selectedItemId}
                    />
                  </div>
                )
              )
            })
          ) : (
            <ListItems
              borders={!meetYourRep && borders}
              listItems={listItems}
              onClick={(event: React.MouseEvent) => getSelectedItem(event)}
              selectedId={selectedItemId}
            />
          )}
        </div>

        {renderBodyContent && (
          <div
            className={styles.bodyContent}
            data-testid="resultview-bodycontent"
            id="resultview-body"
          >
            {renderBodyContent()}
          </div>
        )}

        {isSmallScreen && listItems.length === 0 && renderEmptyState && (
          <div
            className={styles.bodyContent}
            data-testid="resultview-emptystate"
          >
            {renderEmptyState(listItems.length === 0)}
          </div>
        )}
      </div>
    </>
  )
}

export default ResultView
