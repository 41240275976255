import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { Button } from 'components/sparkles'
import { RouteComponentProps } from 'react-router'
import './CommandBar.scss'

export interface Props {
  children?: ReactNode | ReactNode[]
}

const CommandBar: React.FC<Props & Pick<RouteComponentProps, 'history'>> = ({
  children = null,
  history,
}) => {
  const { t } = useTranslation()

  return (
    <div className="CommandBar">
      {history && history.length > 1 && (
        <Button
          className="CommandBar__BackBtn"
          icon={faChevronLeft}
          onClick={() => history.goBack()}
          outline
          variant="default"
        >
          {t('sparkles:CommandBar.back')}
        </Button>
      )}
      {children && <div className="CommandBar__Children">{children}</div>}
    </div>
  )
}

export default CommandBar
