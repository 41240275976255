import * as types from '../actions/constants'

export default (state = null, action) => {
  switch (action.type) {
    case types.SELECT_ISSUE:
      return action.payload || null

    default:
      return state
  }
}
