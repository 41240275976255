import React from 'react'
import { Button } from 'components/sparkles'
import './NextStepButton.css'

const NextStepButton = props =>
  React.createElement(Button, {
    ...Object.assign({}, props, {
      className: 'NextStepButton',
      size: 'lg',
      variant: 'danger',
    }),
  })

export default NextStepButton
