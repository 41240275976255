import React from 'react'
import classNames from 'classnames'
import {
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './notification.module.scss'

export interface NotificationProps {
  align?: 'left' | 'center'
  children: React.ReactNode
  className?: string
  dismissable?: boolean
  onClose?(): void
  size?: 'sm' | 'xs'
  variant: 'success' | 'warning' | 'info' | 'danger'
  withIcon?: boolean
}

const Notification: React.FC<NotificationProps> = ({
  align = 'left',
  children,
  className,
  dismissable,
  onClose,
  size,
  variant,
  withIcon,
}) => {
  return (
    <div
      className={classNames(
        styles.notification,
        size && styles[size],
        variant && styles[variant],
        align && styles[align],
        className,
      )}
      data-cy="notification"
    >
      {withIcon && (
        <FontAwesomeIcon
          className={styles.icon}
          data-testid="notification-icon"
          icon={variant === 'danger' ? faExclamationTriangle : faInfoCircle}
        />
      )}
      <div className={styles.body}>{children}</div>
      {dismissable && (
        <button
          className={styles.close}
          data-cy="notification-close"
          onClick={onClose}
          title="Hide alert"
        >
          <FontAwesomeIcon icon={faTimes} size="2x" />
        </button>
      )}
    </div>
  )
}

export default Notification
