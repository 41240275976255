import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class MapBox extends React.Component {
  constructor(props) {
    super(props)

    this.map = null
    this.mapContainer = React.createRef()
    this.markers = []
  }

  initMap = () => {
    const { center, onDropPin } = this.props
    if (this.mapContainer.current && window.google && window.google.maps) {
      if (this.props.canDropPin) {
        this.map = new window.google.maps.Map(this.mapContainer.current, {
          disableDefaultUI: false,
          zoom: 8,
          center: center || { lat: 39.8283, lng: -98.5795 }, // Center of USA
        })
        this.map.addListener('click', e => {
          this.clearMarkers()
          this.markers = [this.placeMarker('#00b060', e.latLng)]
          onDropPin && onDropPin(e.latLng)
        })
      } else {
        this.map = new window.google.maps.Map(this.mapContainer.current, {
          center,
          disableDefaultUI: true,
          zoom: 8,
        })

        this.placeMarkers()
        this.fitBoundsToVisibleMarkers()
      }
    }
  }

  componentDidMount() {
    if (!window.google) {
      window.mapBoxCallback = this.initMap
    } else {
      this.initMap()
    }
  }

  componentDidUpdate(prevProps) {
    const { center, pollingPlaces } = this.props

    if (!this.map) {
      this.initMap()
    }

    if (
      this.map &&
      (prevProps.center !== center || prevProps.pollingPlaces !== pollingPlaces)
    ) {
      this.map.setCenter(center)
      this.placeMarkers()
      this.fitBoundsToVisibleMarkers()
    }
  }

  /**
   * Clears out old markers
   *
   * We need to do this in order to make sure no rogue markers are left behind
   * when switching selected polling date.
   */
  clearMarkers() {
    this.markers.forEach(marker => marker.setMap(null))
    this.markers = []
  }

  fitBoundsToVisibleMarkers() {
    const googleMap = this.map
    const bounds = new window.google.maps.LatLngBounds()

    /**
     * Zoom out if this zoomed in too much (e.g. when there's only a single
     * marker
     */
    const restoreZoom = () => {
      if (googleMap.getZoom() > 16) {
        googleMap.setZoom(16)
      }
    }

    // Used to use addListenerOnce, but can do normal addListener now?
    window.google.maps.event.addListener(this.map, 'zoom_changed', restoreZoom)

    this.markers.forEach(marker => {
      if (marker.getVisible()) {
        bounds.extend(marker.getPosition())
      }
    })

    this.map.fitBounds(bounds)
  }

  placeMarker(fill, latLng, name, onClickFunction) {
    const marker = new window.google.maps.Marker({
      icon: {
        path:
          'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
        fillColor: fill,
        fillOpacity: 0.875,
        anchor: new window.google.maps.Point(0, 0),
        strokeWeight: 0,
        scale: 1,
        labelOrigin: new window.google.maps.Point(0, -32),
      },
      map: this.map,
      label: { color: '#ffffff', fontSize: '2rem', text: '●' },
      position: latLng,
      title: name,
    })
    if (onClickFunction) {
      marker.addListener('click', () => onClickFunction())
    }

    return marker
  }

  placeMarkers() {
    const {
      onPollingPlaceSelect,
      pollingPlaces,
      selectedPollingPlace,
    } = this.props

    this.clearMarkers()

    this.markers = pollingPlaces.map(pollingPlace => {
      let fill = '#707070' // gray
      if (pollingPlace === selectedPollingPlace) {
        fill = '#00b060' // green (brand-success)
      }

      return this.placeMarker(
        fill,
        new window.google.maps.LatLng(pollingPlace.lat, pollingPlace.lon),
        pollingPlace.name,
        () => onPollingPlaceSelect(pollingPlace),
      )
    })
  }

  render() {
    const { className, height } = this.props

    const mapStyle = { width: '100%', height: height || '100%' }

    // Always render this or ref might be undefined in componentDidMount!
    // @see https://stackoverflow.com/questions/44074747/componentdidmount-called-before-ref-callback/50019873#50019873
    return (
      <div
        className={classNames('MapBox', className)}
        ref={this.mapContainer}
        style={mapStyle}
      />
    )
  }
}

MapBox.propTypes = {
  center: PropTypes.object,
  height: PropTypes.number,
  pollingPlaces: PropTypes.array,
  selectedPollingPlace: PropTypes.object,
  onDropPin: PropTypes.func,
}

export default MapBox
