import * as types from 'actions/constants'

const candidates = (
  state = {
    items: {},
    isFetching: false,
  },
  action,
) => {
  switch (action.type) {
    case types.REQUEST_CANDIDATES:
      return {
        ...state,
        isFetching: true,
      }
    case types.RECEIVE_CANDIDATES:
      return {
        ...state,
        items:
          Object.keys(state.items).length > 0
            ? Object.assign(state.items, action.payload)
            : action.payload,
        isFetching: false,
      }
    case types.RESET_CANDIDATES:
      return {
        items: {},
        isFetching: false,
      }
    default:
      return state
  }
}

export default candidates
