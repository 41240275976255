import React, { useState } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown'
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'

import { Markdown } from '../../atoms'
import styles from './checkListItem.module.scss'
import { CheckOrX } from 'types'

export interface CheckListItemProps {
  item: CheckOrX
}

const CheckListItem: React.FC<CheckListItemProps> = ({ item }) => {
  const [open, setOpen] = useState(false)

  const checkIcon = (value: string) => {
    switch (value && value.toLowerCase()) {
      case 'check':
        return faCheck
      case 'x':
        return faTimes
      default:
        return faMinus
    }
  }

  const togglable = !!item.field_description
  return (
    <div
      className={classNames(styles.checkListItem, styles[item.checkOrX])}
      data-cy="checklistitem"
    >
      <header className={styles.header}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={checkIcon(item.checkOrX)} size="2x" />
        </div>
        <div className={styles.title}>{item.field_name}</div>
        {togglable && (
          <button
            aria-label="Toggle"
            data-cy="checklistitem-toggle"
            className={styles.toggle}
            onClick={() => setOpen(!open)}
          >
            <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
          </button>
        )}
      </header>
      {togglable && (
        <Markdown
          className={classNames(
            styles.description,
            open && styles.description__open,
          )}
          source={item.field_description}
        />
      )}
    </div>
  )
}

export default CheckListItem
