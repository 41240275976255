import * as types from 'actions/constants'

const initialState = {
  officeHolders: [],
  partyLeaders: [],
  isFetching: false,
  errorMessage: null,
}

export default (state = initialState, { type, key, payload }) => {
  switch (type) {
    case types.RECEIVE_OFFICE_HOLDERS:
      const newState = {
        ...state,
        isFetching: false,
        errorMessage: null,
      }
      newState[key] = payload
      return newState
    case types.REQUEST_OFFICE_HOLDERS:
      return {
        ...state,
        isFetching: true,
      }
    case types.ERROR_OFFICE_HOLDERS:
      return {
        ...state,
        errorMessage: payload,
      }
    default:
      return state
  }
}
