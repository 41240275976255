import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { StoreState } from 'types'
import fetchElection from './fetchElection'
import clearElection from './clearElection'

export default (): ThunkAction<void, StoreState, null, AnyAction> => async (
  dispatch,
  getState,
) => {
  const { ballot } = getState()

  if (ballot?.election_id) {
    return dispatch(fetchElection(ballot.election_id))
  } else {
    return dispatch(clearElection())
  }
}
