import React from 'react'
import styles from './DirectoryHero.module.scss'
import AddressForm from 'components/AddressForm/AddressForm'

const DirectoryHero = () => {
  return (
    <section className={styles.Hero}>
      <div className={styles.Hero__CTA}>
        <header className={styles.Hero__Header}>
          <p>
            <strong>
              Enter your address to view your personalized ballot.
            </strong>{' '}
            Check your registration, make a plan to vote, and research every
            name and measure on the ballot with BallotReady.
          </p>
        </header>
        {/* 
        // @ts-ignore */}
        <AddressForm />
      </div>
    </section>
  )
}

export default DirectoryHero
