import React, { useEffect, useRef, useState } from 'react'
import { Candidate, Position } from 'types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons'
import throttle from 'lodash/throttle'

import { CandidacyListItem } from '../../molecules'
import styles from './candidateListSmall.module.scss'

export interface CandidateListSmallProps {
  activeCandidateId: number | null
  candidates?: Candidate[]
  className?: string
  enableTooltip?: boolean
  hasBadge?: boolean
  inDirectory?: boolean
  onCandidateClick?(event: React.MouseEvent | React.KeyboardEvent): void
  position: Position
  shouldRedirect: boolean
}

const CandidateListSmall: React.FC<CandidateListSmallProps> = ({
  activeCandidateId,
  candidates,
  className,
  enableTooltip = true,
  inDirectory = false,
  hasBadge = false,
  onCandidateClick,
  position,
  shouldRedirect,
}) => {
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollLeftMax, setScrollLeftMax] = useState(0)

  const scrollContainerRef = useRef<HTMLElement | null>(null)

  const scrollTo = (direction: 'left' | 'right') => {
    const container = scrollContainerRef?.current

    if (!container) {
      return
    }

    const currentScroll = container?.scrollLeft
    let scrollSize = container?.clientWidth || 0

    if (direction === 'left') {
      scrollSize = -scrollSize
    }

    container.scrollLeft = currentScroll + scrollSize
  }

  useEffect(() => {
    const container = scrollContainerRef.current
    if (!container) {
      return
    }

    const newScrollLeftMax = container.scrollWidth - container.clientWidth

    if (scrollLeftMax !== newScrollLeftMax) {
      setScrollLeftMax(newScrollLeftMax)
    }
  }, [candidates, scrollLeftMax])

  const handleScroll = () => {
    const container = scrollContainerRef.current

    if (!container) {
      return
    }
    setScrollLeft(container.scrollLeft)
  }

  const handleScrollThrottled = throttle(handleScroll, 1000)

  return (
    <div
      className={classNames(styles.container_outer, className)}
      data-cy="candidatelistsmall"
    >
      <nav
        className={classNames(
          styles.nav,
          scrollLeft === 0 && styles.right_only,
        )}
      >
        <button
          className={classNames(
            styles.navBtn,
            scrollLeft === 0 && styles.hidden,
          )}
          data-cy="candidatelistsmall-left"
          onClick={() => scrollTo('left')}
          title="left"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <button
          className={classNames(
            styles.navBtn,
            scrollLeft >= scrollLeftMax && styles.hidden,
          )}
          data-cy="candidatelistsmall-right"
          onClick={() => scrollTo('right')}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </nav>
      <section
        className={classNames(
          styles.candidateListContainer,
          hasBadge && styles.hasBadge,
        )}
        onScroll={handleScrollThrottled}
        ref={scrollContainerRef}
      >
        <ul className={styles.candidateList}>
          {candidates?.map((candidate: Candidate) => {
            return (
              <li
                className={styles.item}
                data-cy="candidatelistsmall-li"
                key={candidate.id}
              >
                <CandidacyListItem
                  active={
                    !!(
                      activeCandidateId &&
                      (activeCandidateId === candidate.id ||
                        activeCandidateId === candidate.candidate_id)
                    )
                  }
                  candidate={candidate}
                  inDirectory={inDirectory}
                  onClick={onCandidateClick}
                  position={position}
                  small={true}
                  enableTooltip={enableTooltip}
                  shouldRedirect={shouldRedirect}
                />
              </li>
            )
          })}
        </ul>
      </section>
    </div>
  )
}

export default CandidateListSmall
