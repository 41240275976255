import qs from 'qs'

import * as types from 'actions/constants'
import apiFetch from './apiFetch'

export const invalidateCandidates = positionElectionId => ({
  type: types.INVALIDATE_POSITION_ELECTION_CANDIDATES,
  positionElectionId,
})

const receivePositionCandidates = (payload, positionElectionId) => ({
  type: types.RECEIVE_POSITION_ELECTION_CANDIDATES,
  payload,
  positionElectionId,
  receivedAt: Date.now(),
})

const requestPositionCandidates = positionElectionId => ({
  type: types.REQUEST_POSITION_ELECTION_CANDIDATES,
  positionElectionId,
})

const fetchCandidates =
  (positionElectionId, options, candidateIdWhitelist) => async dispatch => {
    dispatch(requestPositionCandidates(positionElectionId))

    const params = qs.stringify(options)
    const response = await dispatch(
      apiFetch(
        `/ballot/position-elections/${positionElectionId}/candidates?${params}`,
      ),
    )

    if (!response.ok) {
      return
    }

    const candidates = await response.json()
    let filteredCandidates = candidates
    if (filteredCandidates) {
      if (candidateIdWhitelist) {
        filteredCandidates = candidates.filter(
          c => candidateIdWhitelist.indexOf(c.id) !== -1,
        )
      }
      return dispatch(
        receivePositionCandidates(filteredCandidates, positionElectionId),
      )
    }
  }

const shouldFetchCandidates = (state, positionElectionId) => {
  const candidates = state.candidatesByPositionElection[positionElectionId]

  if (!candidates) {
    return true
  }

  if (candidates.isFetching) {
    return false
  }

  return candidates.didInvalidate
}

export default (positionElectionId, options) => async (dispatch, getState) => {
  if (shouldFetchCandidates(getState(), positionElectionId)) {
    const {
      appConfig: { candidateIdWhitelist },
    } = getState()

    return dispatch(
      fetchCandidates(
        positionElectionId,
        options,
        candidateIdWhitelist ? candidateIdWhitelist[positionElectionId] : null,
      ),
    )
  }
}
