import {
  RECEIVE_BALLOT,
  SAVED_BALLOT,
  SIGN_OUT,
  CLEAR_BALLOT,
} from 'actions/constants'

export default (state = null, { type, payload }) => {
  switch (type) {
    case RECEIVE_BALLOT:
      return payload
    case SAVED_BALLOT:
      return Object.assign({}, state, payload)
    case SIGN_OUT:
    case CLEAR_BALLOT:
      return null
    default:
      return state
  }
}
