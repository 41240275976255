import React from 'react'
import styles from './SectionSeparatorLine.module.scss'

interface Props {
  text: string
}

const SectionSeparatorLine: React.FC<Props> = ({ text }) => (
  <div className={styles.textLine}>{text}</div>
)

export default SectionSeparatorLine
