import * as types from '../actions/constants'

export default (state = true, action) => {
  switch (action.type) {
    case types.TOGGLE_GLOBAL_ALERT:
      return action.value
    default:
      return state
  }
}
