import React from 'react'
import classNames from 'classnames'

import { CandidateUrl } from 'types'
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import Button from '../../atoms/Button'

import styles from './candidateUrls.module.scss'

export interface CandidateUrlProps {
  className?: string
  urls: CandidateUrl[]
}

const CandidateUrls: React.FC<CandidateUrlProps> = ({ className, urls }) => {
  if (!Array.isArray(urls) || urls.length === 0) {
    return null
  }

  const linkTypeIcon = (type: string) => {
    switch (type) {
      case 'facebook':
        return faFacebookF
      case 'twitter':
        return faTwitter
      case 'instagram':
        return faInstagram
      case 'linkedin':
        return faLinkedin
      default:
        return faLink
    }
  }

  return (
    <div className={classNames(styles.root, className)}>
      {urls.map((url, key) => {
        if (url.type !== 'volunteer' && url.type !== 'donate') {
          return (
            <Button
              aria-label={`${url.type} link`}
              data-testid="candidate-url"
              href={url.url}
              icon={linkTypeIcon(url.type)}
              key={key}
              variant="white"
            />
          )
        } else return null
      })}
    </div>
  )
}

export default CandidateUrls
