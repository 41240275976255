import * as types from 'actions/constants'

const usState = (
  state = {
    isFetching: false,
    items: null, // TODO This seems like it would be an array, but it's not. It's a (US) State object
    lastUpdated: null,
  },
  action,
) => {
  switch (action.type) {
    case types.REQUEST_STATE:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case types.RECEIVE_STATE:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.payload,
        lastUpdated: action.receivedAt,
      })
    default:
      return state
  }
}

export default (state = {}, action) => {
  switch (action.type) {
    case types.RECEIVE_STATE:
    case types.REQUEST_STATE:
      return Object.assign({}, state, {
        [action.stateShortName]: usState(state[action.stateShortName], action),
      })
    default:
      return state
  }
}
