import React from 'react'
import styles from './contactInformation.module.scss'

export interface ContactInfoProps {
  email?: string
  phone?: string
}

const ContactInformation: React.FC<ContactInfoProps> = ({ email, phone }) => {
  const emails = email?.split(',')
  return (
    <div className={styles.contactInfo} data-cy="contactinformation">
      <a data-cy="phone" href={`tel:${phone}`}>
        {phone}
      </a>
      {emails?.map(e => (
        <a data-cy="email" key={e} href={`mailto:${e}`}>
          {e}
        </a>
      ))}
    </div>
  )
}

export default ContactInformation
