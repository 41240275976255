import React from 'react'
import classNames from 'classnames'
import './ProgressBar.scss'

interface Props {
  className?: string
  compact?: boolean
  max: number
  value: number
  variant?: 'white' | undefined
}

const ProgressBar: React.FC<Props> = ({
  className,
  compact,
  max,
  value,
  variant,
}: Props) => {
  return (
    <div
      className={classNames(
        'ProgressBar',
        {
          'ProgressBar--compact': compact,
          [`ProgressBar--${variant}`]: variant,
        },
        className,
      )}
    >
      <progress
        id="progress"
        max={max}
        value={value}
        aria-valuenow={(value / max) * 100}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
  )
}

export default ProgressBar
