import toggleGlobalAlert from 'actions/toggleGlobalAlert'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { StoreState } from 'types'

const mapStateToProps = ({
  appConfig: {
    defaultElectionId,
    disclaimer,
    homePath,
    universe: { supportPortalUrl },
  },
  ballot,
  browser,
  showGlobalAlert,
}: StoreState) => ({
  ballot,
  defaultElectionId,
  disclaimer,
  homePath,
  isSmallScreen: browser.lessThan.medium,
  showGlobalAlert,
  supportPortalUrl,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, null, AnyAction>,
) => ({
  dismissGlobalAlert: () => dispatch(toggleGlobalAlert(false)),
})

export default connect(mapStateToProps, mapDispatchToProps)
