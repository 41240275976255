import React from 'react'
import classNames from 'classnames'
import styles from './badge.module.scss'

interface BadgeProps {
  className?: string
  variant?: string
}

const Badge: React.FC<BadgeProps> = ({
  children,
  className,
  variant = 'primary',
}) => {
  return (
    <span
      className={classNames(
        styles.badge,
        variant && styles[`${variant}`],
        className,
      )}
    >
      {children}
    </span>
  )
}

export default Badge
