import * as types from 'actions/constants'

const issues = (
  state = {
    didInvalidate: false,
    isFetching: false,
    items: [],
    lastUpdated: null,
  },
  action,
) => {
  switch (action.type) {
    case types.INVALIDATE_POSITION_ELECTION_CANDIDATES:
      return Object.assign({}, state, {
        didInvalidate: true,
      })
    case types.REQUEST_POSITION_ELECTION_CANDIDATES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      })
    case types.RECEIVE_POSITION_ELECTION_CANDIDATES:
      // Used to keep track of unique ids so we can filter duplicates
      const uniqueIssueIds = []
      const uniqueIssues = []
        .concat(...action.payload.map(({ issues }) => issues))
        .filter(issue => {
          if (uniqueIssueIds.includes(issue.id)) {
            return false
          }

          uniqueIssueIds.push(issue.id)
          return true
        })
        .map(issue => {
          const { stances, ...issueWithoutStances } = issue
          return issueWithoutStances
        })

      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: uniqueIssues,
        lastUpdated: action.receivedAt,
      })
    default:
      return state
  }
}

export default (state = {}, action) => {
  switch (action.type) {
    case types.INVALIDATE_POSITION_ELECTION_CANDIDATES:
    case types.REQUEST_POSITION_ELECTION_CANDIDATES:
    case types.RECEIVE_POSITION_ELECTION_CANDIDATES:
      return Object.assign({}, state, {
        [action.positionElectionId]: issues(
          state[action.positionElectionId],
          action,
        ),
      })
    default:
      return state
  }
}
