import { RECEIVE_BALLOT, REQUEST_BALLOT } from 'actions/constants'
import apiFetch from './apiFetch'
import { Ballot, StoreState } from 'types'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'

const receiveBallot = (payload: Ballot) => ({ type: RECEIVE_BALLOT, payload })
const requestBallot = () => ({ type: REQUEST_BALLOT })

export default (): ThunkAction<
  void,
  StoreState,
  unknown,
  AnyAction
> => async dispatch => {
  dispatch(requestBallot())
  const response = await dispatch(apiFetch('/ballot'))

  if (!response.ok) {
    return
  }

  const json = await response.json()

  if (json) {
    return dispatch(receiveBallot(json))
  }
}
