import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'

import styles from './checkIcon.module.scss'

interface Props {
  checked?: boolean
  className?: string
}

const CheckIcon: React.FC<Props> = ({ checked = false, className }) => {
  return (
    <FontAwesomeIcon
      className={classNames(
        styles.root,
        {
          [styles.checked]: checked,
        },
        className,
      )}
      data-testid={`checkIcon${checked ? '-checked' : ''}`}
      icon={faCheckCircle}
      size="2x"
    />
  )
}

export default CheckIcon
