import * as types from 'actions/constants'

export default (state = null, { type, payload }) => {
  switch (type) {
    case types.RECEIVE_SHARED_BALLOT:
      return payload
    default:
      return state
  }
}
