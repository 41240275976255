import * as types from 'actions/constants'
import apiFetch from './apiFetch'

export const savedLead = payload => ({
  type: types.SAVED_LEAD,
  payload,
})

export default attributes => async dispatch => {
  const response = await dispatch(
    apiFetch(`leads`, {
      method: 'POST',
      body: JSON.stringify(attributes),
    }),
  )

  if (!response.ok) {
    return
  }

  dispatch(savedLead(attributes))
}
