import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { faAngleLeft, faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner } from 'components/sparkles'
import SearchDropdown from '../SearchDropdown/SearchDropdown'
import searchBarContainer from './searchBarContainer'
import styles from './SearchBar.module.scss'
import { SavedSearch } from 'appConfig'
import useAnalytics from 'hooks/useAnalytics'
import { AnalyticsContextType } from 'contexts/AnalyticsContext/AnalyticsContext'

interface Props {
  className?: string
  isSmallScreen: boolean
  isInNav?: boolean
  savedSearches: SavedSearch[]
  search(searchTerm: string, analytics?: AnalyticsContextType): void
  searching: boolean
  searchInitiated: boolean
  searchResults: any[]
  searchTerm: string
  setSearchInitiated(value: any): void
}

const SearchBar = ({
  className,
  isInNav = false,
  isSmallScreen,
  savedSearches,
  search,
  searching,
  searchInitiated = false,
  searchResults,
  setSearchInitiated,
  searchTerm,
}: Props) => {
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState(searchTerm ? searchTerm : '')
  const analytics = useAnalytics()

  useEffect(() => {
    setSearchQuery(searchTerm)
  }, [searchTerm])

  // we want the dropdown or overlay to open on click
  const handleClick = (event: React.MouseEvent) => {
    setSearchInitiated(true)
  }

  // we want results to start populating on change

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //https://reactjs.org/docs/legacy-event-pooling.html
    event.persist()
    setSearchQuery(event.target.value)
    if (event.target.value.length > 3) {
      const delayDebounceFn = setTimeout(() => {
        search(event.target.value, analytics)
      }, 2000)
      return () => clearTimeout(delayDebounceFn)
    }
  }

  useEffect(() => {
    if (isSmallScreen) {
      searchInitiated && (document.body.style.overflow = 'hidden')
      !searchInitiated && (document.body.style.overflow = 'unset')
    }

    // cleanup overflow hidden when leaving the search dropdown
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [searchInitiated, isSmallScreen])

  return (
    <div
      className={classNames(
        styles.searchContainer,
        isInNav && styles.isInNav,
        searchInitiated &&
          isSmallScreen &&
          !isInNav &&
          styles.mobileSearchContainer,
      )}
    >
      {searchInitiated && isSmallScreen && !isInNav && (
        // TODO: make this accessible
        <span className={styles.back}>
          <FontAwesomeIcon icon={faAngleLeft} /> {t('common:back')}
        </span>
      )}
      <div
        className={classNames(className, styles.search)}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleClick(event)
        }
      >
        <FontAwesomeIcon icon={faSearch} />
        <input
          onChange={event => handleChange(event)}
          placeholder={t('common:search')}
          value={searchQuery}
        />
        {searching && <Spinner className={styles.loading} size="xs" />}
      </div>
      {searchInitiated && (
        <SearchDropdown
          close={(event: React.MouseEvent) => {
            event.stopPropagation()
            setTimeout(() => {
              setSearchInitiated(false)
            }, 500)
          }}
          isSmallScreen={isSmallScreen}
          savedSearches={savedSearches}
          search={search}
          searching={searching}
          searchResults={searchResults}
          searchTerm={searchTerm}
          setSearchInitiated={setSearchInitiated}
        />
      )}
    </div>
  )
}

export default searchBarContainer(SearchBar)
