import { AnyAction } from 'redux'
import * as types from '../actions/constants'

export default (state = true, action: AnyAction): boolean => {
  switch (action.type) {
    case types.TOGGLE_VOTER_GUIDE_ALERT:
      return action.payload
    default:
      return state
  }
}
