import React from 'react'
import classNames from 'classnames'
import Select, { components, MenuProps } from 'react-select'
import { useTranslation } from 'react-i18next'
import { ValueType } from 'react-select/src/types'
import { OptionType } from 'types'
import styles from './LocaleSwitcher.module.scss'
import useAnalytics from 'hooks/useAnalytics'

export interface Props {
  className?: string
  currentLocale: string
  locales: string[]
  onChange(locale?: string | null): void
}

// Help widget determine how big to grow
const Menu: React.FC<MenuProps<OptionType>> = props => (
  <components.Menu {...props}>
    <>
      {props.children}
      <span data-iframe-height></span>
    </>
  </components.Menu>
)

const LocaleSwitcher: React.FC<Props> = ({
  className,
  currentLocale,
  locales = [],
  onChange,
}) => {
  const { t } = useTranslation()
  const analytics = useAnalytics()

  const handleChange = React.useCallback(
    (value: ValueType<OptionType>): void => {
      if (value) {
        const language = (value as OptionType).value
        analytics?.track('Language changed', { language })
        onChange(language)
      }
    },
    [analytics, onChange],
  )

  const options = locales.map(locale => {
    const localeComponents = locale.split('-')

    // Turns a string like 'es-US-cof70' into an array :
    // ["locales.en-US-cof70", "locales.en-US", "locales.en"]
    // We can use this array to fallback through the locales until i18next finds
    // a translated value.
    const labelLocales = localeComponents
      .map(
        (_: string, i: number) =>
          `locales.${localeComponents.slice(0, i + 1).join('-')}`,
      )
      .reverse()
      .filter((el: string) => el)

    return {
      label: t(labelLocales),
      value: locale,
    }
  })
  const selected = options.find(o => o.value === currentLocale)

  return (
    <Select
      aria-labelledby="languageSelect"
      className={classNames(styles.switcher, className)}
      components={{ Menu }}
      defaultValue={selected}
      menuPlacement="top"
      onChange={handleChange}
      options={options}
      placeholder={t('sparkles:LocaleSwitcher.placeholder')}
    />
  )
}

export default LocaleSwitcher
