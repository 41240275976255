import React from 'react'
import { Spinner } from 'components/sparkles'
import styles from './FetchingScene.module.scss'
import classNames from 'classnames'

interface Props {
  className?: string
}

const FetchingScene: React.FC<Props> = ({ children, className }) => (
  <div className={classNames(styles.scene, className)}>
    <Spinner size="lg" />
    {children && <div className={styles.message}>{children}</div>}
  </div>
)

export default FetchingScene
