import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink'
import Markdown from '../Markdown'
import styles from './sourcedSnippet.module.scss'

export interface SourcedSnippetProps {
  className?: string
  snippet: string
  sourceUrl: string | undefined
}

const SourcedSnippet: React.FC<SourcedSnippetProps> = ({
  className,
  snippet,
  sourceUrl,
}) => (
  <a
    className={classNames(styles.snippet, className)}
    data-testid="sourced-snippet"
    href={sourceUrl}
    rel="noopener noreferrer"
    target="_blank"
  >
    <div>
      <Markdown source={snippet} />
    </div>
    {sourceUrl && (
      <sup>
        <FontAwesomeIcon icon={faExternalLink} />
      </sup>
    )}
  </a>
)

export default SourcedSnippet
