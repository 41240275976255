import Cookies from 'js-cookie'
import signIn from 'actions/signIn'
import selectCandidate from 'actions/selectCandidate'
import selectPosition from 'actions/selectPosition'

export default (history, service, user) => async (dispatch, getState) => {
  const currentBallotId = getState().auth.user.ballot_id

  await dispatch(signIn(service, user))

  const {
    appConfig: { homePath },
    auth,
  } = getState()
  if (!auth.error) {
    if (currentBallotId !== auth.user.ballot_id) {
      Cookies.set(
        'ce_s',
        {
          bid: auth.user.ballot_id,
          et: auth.user.engine_token,
          uid: auth.user.ballot_user_id,
        },
        {
          expires: 30,
        },
      )

      // Clear current selections
      await dispatch(selectCandidate(null))
      await dispatch(selectPosition(null))
    }

    history.push(homePath)
  }
}
