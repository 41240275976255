import {
  RECEIVE_CANDIDATE,
  RECEIVE_POSITION_ELECTION_CANDIDATES,
  SELECT_CANDIDATE,
} from 'actions/constants'

export default (state = null, action) => {
  switch (action.type) {
    case SELECT_CANDIDATE:
      return action.candidate || null
    case RECEIVE_CANDIDATE:
      return action.payload
    // When receiving a list of candidates update locally selected candidate
    // with candidate from API.
    case RECEIVE_POSITION_ELECTION_CANDIDATES:
      if (state) {
        // returning `|| state` because I don't want to change current candidate
        // if this is not the current position.
        //
        // I used to return `|| null` here but then it would reset current
        // candidate on hard page refresh/load.
        return (
          action.payload.find(
            candidate =>
              candidate.slug === state.slug || candidate.id === state.id,
          ) || state
        )
      } else {
        return state
      }

    default:
      return state
  }
}
