import * as types from 'actions/constants'

export default (
  state = {
    callCompleted: false,
  },
  { type, payload },
) => {
  switch (type) {
    case types.COMPLETED_SUBSCRIPTION_CALL:
      return Object.assign({}, state, {
        callCompleted: true,
      })
    default:
      return state
  }
}
