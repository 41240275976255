import React from 'react'
import { Party } from 'types'
import classNames from 'classnames'

import candidatePartyNames from 'utils/candidatePartyNames'
import styles from './partyInitials.module.scss'

export interface PartyInitialsProps {
  className?: string
  parties: Party[]
}

const PartyInitials: React.FC<PartyInitialsProps> = ({
  className,
  parties,
}) => {
  if (!parties || parties.length === 0) {
    return null
  }
  return (
    <span
      className={classNames(styles.root, className)}
      aria-label={parties.map(party => candidatePartyNames([party])).join(' ')}
      data-testid="party-initials"
    >
      {`(` + parties.map(party => party.short_name) + `)`}
    </span>
  )
}

export default PartyInitials
