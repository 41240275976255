import React, { useState } from 'react'
import classNames from 'classnames'
import { ErrorMessage, Formik, Form, Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil'
import { Button, Markdown, ShareCard } from 'components/sparkles'
import './SharedBallotHeader.scss'

interface Props {
  bio: string
  editable: boolean
  name: string
  onSubmit(values: { bio: string; name: string }): void
}

const SharedBallotHeader: React.FC<Props> = ({
  bio,
  editable,
  name,
  onSubmit,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [formValues, setFormValues] = useState({ name, bio })
  const displayName = formValues.name || 'Anonymous'

  return (
    <ShareCard className="SharedBallotHeader">
      <h2>This ballot belongs to</h2>

      <Formik
        initialValues={{
          bio: formValues.bio || '',
          name: formValues.name || '',
        }}
        onSubmit={values => {
          setIsEditing(false)
          onSubmit(values)
          setFormValues(values)
        }}
        validate={values => {
          let errors: { name?: string; bio?: string } = {}
          if (values.name.length > 60) {
            errors['name'] = 'Maximum length is 60 characters'
          }
          return errors
        }}
      >
        {() => (
          <Form className="SharedBallotHeader__NameForm">
            <h1
              className={classNames('SharedBallotHeader__Field', {
                'SharedBallotHeader__Field--editable': editable && !isEditing,
              })}
              onClick={() => {
                if (editable) {
                  setIsEditing(true)
                }
              }}
            >
              {isEditing ? (
                <Field name="name" placeholder="Your name" />
              ) : (
                displayName
              )}
              {editable && !isEditing && (
                <sup>
                  <FontAwesomeIcon
                    className="SharedBallotHeader__Edit"
                    icon={faPencil}
                    onClick={() => setIsEditing(true)}
                  />
                </sup>
              )}
            </h1>
            <ErrorMessage name="name" component="div" />

            {isEditing ? (
              <Field
                component="textarea"
                name="bio"
                placeholder="Add short bio here..."
                rows={6}
              />
            ) : (
              (formValues.bio || editable) && (
                <div
                  className={classNames('SharedBallotHeader__Field', {
                    'SharedBallotHeader__Field--editable':
                      editable && !isEditing,
                  })}
                  onClick={() => {
                    if (editable) {
                      setIsEditing(true)
                    }
                  }}
                >
                  {editable && !isEditing && (
                    <sup>
                      <FontAwesomeIcon
                        className="SharedBallotHeader__Edit"
                        icon={faPencil}
                        onClick={() => setIsEditing(true)}
                      />
                    </sup>
                  )}
                  {formValues.bio ? (
                    <Markdown source={formValues.bio} />
                  ) : (
                    <em>Add short bio here...</em>
                  )}
                </div>
              )
            )}

            {isEditing && (
              <div className="SharedBallotHeader__Actions">
                <Button
                  onClick={() => setIsEditing(false)}
                  outline
                  variant="default"
                >
                  Cancel
                </Button>
                <Button
                  outline
                  type="submit"
                  variant="primary"
                  disabled={!isEditing}
                >
                  Save
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </ShareCard>
  )
}

export default SharedBallotHeader
