import { Education, Experience } from 'types'
import React from 'react'

interface Props {
  formatter(e: Education | Experience): string
  items?: (Education | Experience)[]
  title: string
}

const DataPoints: React.FC<Props> = ({ formatter, items, title }) => {
  if (!items || items.length === 0) {
    return null
  }

  return (
    <div className="CandidateCard__DataPoints">
      <h4>{title}</h4>
      <ul>
        {items.map((item, key) => {
          return <li key={key}>{formatter(item as Education | Experience)}</li>
        })}
      </ul>
    </div>
  )
}

export default DataPoints
