import { RequestBallotSelections } from 'types'
import {
  BEGIN_REQUEST_FLOW,
  BEGIN_RETURN_FLOW,
  REQUESTING_BALLOT,
  SAVE_ABSENTEE_BALLOT_FORM,
  SAVE_ABSENTEE_BALLOT_REMINDER,
  SELECT_IF_REGISTERED,
  SELECT_REGISTRATION_FAILED_OPTION,
  TOGGLE_PRINT_BALLOT_REQUEST_STEP,
  ACQUIRED_FORM,
  DISMISS_AUTOMATIC_VBM_ALERT,
  REMOVE_UPLOADED_ID,
  REQUEST_IN_PERSON_REMINDERS,
  USE_FILLED_FORM,
  PRINT_BALLOT_REQUEST_SUBMITTED,
  SET_ATTEMPTING_FORM_SUBMIT,
  ID_UPLOAD_REQUIRED,
  SET_VOTER_REGISTRATION_METHOD,
  RECEIVE_BALLOT_PLACES,
  RECEIVE_BALLOT,
  REQUEST_CHECK_REGISTRATION,
  RECEIVE_CHECK_REGISTRATION,
  RECEIVE_ID_UPLOAD_PARAMS,
  SAVE_FAILED_ABSENTEE_BALLOT_REQUEST_FORM,
  SAVED_ABSENTEE_BALLOT_REQUEST_FORM,
  SET_REGISTRATION_FLOW_COMPLETED,
} from 'actions/constants'
import { AnyAction } from 'redux'
import { findFieldForMostSpecificPlaceString } from 'utils/requestBallotUtils'

const initialState: RequestBallotSelections = {
  absenteeBallotRequestForm: undefined,
  acquiredForm: false,
  checkingRegistration: false,
  checkRegistrationUrl: null,
  communication: { send_to_customer: false },
  dismissedAutomaticVBMAlert: false,
  isFormFilled: false,
  isAttemptingFormSubmit: null,
  isRegistered: undefined,
  printBallotRequestSubmitted: false,
  showPrintBallotRequestStep: false,
  registration: undefined,
  registrationFailedOption: undefined,
  registrationFlowCompleted: false,
  registrationUrl: null,
  requestBallotPortalUrl: null,
  requestingBallot: false,
  requestInPersonReminders: false,
  requestStarted: false,
  returnStarted: false,
  showIdUploadStep: false,
  useFilledForm: false,
  uploadId: undefined,
  verificationRequestData: null,
}

export default (
  state = initialState,
  action: AnyAction,
): RequestBallotSelections => {
  switch (action.type) {
    case BEGIN_REQUEST_FLOW:
      return {
        ...state,
        requestStarted: action.value,
      }
    case REQUESTING_BALLOT:
      return {
        ...state,
        requestingBallot: action.payload,
      }
    case RECEIVE_BALLOT_PLACES:
      const places = action.payload
      return {
        ...state,
        checkRegistrationUrl: findFieldForMostSpecificPlaceString(
          places,
          'voter_check_registration_url',
        ),
        registrationUrl: findFieldForMostSpecificPlaceString(
          places,
          'voter_registration_url',
        ),
        requestBallotPortalUrl: findFieldForMostSpecificPlaceString(
          places,
          'vbm_portal_url',
        ),
      }
    case RECEIVE_BALLOT:
      // Reset choices with new ballot
      return {
        ...state,
        absenteeBallotRequestForm: undefined,
      }
    case SAVE_ABSENTEE_BALLOT_FORM:
      return {
        ...state,
        absenteeBallotRequestForm: Object.assign(
          {},
          state.absenteeBallotRequestForm,
          action.payload,
        ),
        isFormFilled: action.payload.isFormFilled, // TODO make this conditional
      }
    case SAVE_ABSENTEE_BALLOT_REMINDER:
      return {
        ...state,
        communication: Object.assign({}, state.communication, action.payload),
      }
    case SELECT_IF_REGISTERED:
      return {
        ...state,
        isRegistered: action.value,
      }
    case REQUEST_CHECK_REGISTRATION:
      // save request data in redux store for form fill
      return {
        ...state,
        checkingRegistration: true,
        verificationRequestData: action.registrationData,
      }
    case RECEIVE_CHECK_REGISTRATION:
      return {
        ...state,
        registration: action.payload,
        checkingRegistration: false,
      }
    case SELECT_REGISTRATION_FAILED_OPTION:
      return {
        ...state,
        registrationFailedOption: action.value,
      }
    case TOGGLE_PRINT_BALLOT_REQUEST_STEP:
      return {
        ...state,
        showPrintBallotRequestStep: action.value,
      }
    case ACQUIRED_FORM:
      return {
        ...state,
        acquiredForm: true,
      }
    case DISMISS_AUTOMATIC_VBM_ALERT:
      return {
        ...state,
        dismissedAutomaticVBMAlert: true,
      }
    case REQUEST_IN_PERSON_REMINDERS:
      return {
        ...state,
        requestInPersonReminders: action.value,
      }
    case USE_FILLED_FORM:
      return {
        ...state,
        useFilledForm: action.value,
      }
    case PRINT_BALLOT_REQUEST_SUBMITTED:
      return {
        ...state,
        printBallotRequestSubmitted: true,
      }
    case RECEIVE_ID_UPLOAD_PARAMS:
      return {
        ...state,
        uploadId: action.payload.fields.key,
      }
    case REMOVE_UPLOADED_ID:
      return {
        ...state,
        uploadId: undefined,
      }
    case SAVE_FAILED_ABSENTEE_BALLOT_REQUEST_FORM:
      return {
        ...state,
        formError: action.payload,
        isAttemptingFormSubmit: true,
      }
    case SAVED_ABSENTEE_BALLOT_REQUEST_FORM:
      return {
        ...state,
        formError: undefined,
        isAttemptingFormSubmit: false,
      }
    case SET_ATTEMPTING_FORM_SUBMIT:
      return {
        ...state,
        isAttemptingFormSubmit: true,
      }
    case ID_UPLOAD_REQUIRED:
      return {
        ...state,
        showIdUploadStep: action.value,
      }
    case BEGIN_RETURN_FLOW:
      return {
        ...state,
        returnStarted: action.value,
      }
    case SET_VOTER_REGISTRATION_METHOD:
      return {
        ...state,
        voterRegistrationMethod: action.payload,
      }
    case SET_REGISTRATION_FLOW_COMPLETED:
      return {
        ...state,
        registrationFlowCompleted: action.value,
      }
    default:
      return state
  }
}
