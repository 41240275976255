import { Party } from 'types'

export default (parties: Party[]) => {
  return parties
    .map(p => {
      switch (p.id) {
        case 2: // Exception for democrats
          return 'Democrat'
        default:
          return p.name
      }
    })
    .join(', ')
}
