import { RECEIVE_ELECTION, REQUEST_ELECTION } from 'actions/constants'
import apiFetch from './apiFetch'

const receiveElection = payload => ({
  type: RECEIVE_ELECTION,
  payload,
})
const requestElection = () => ({ type: REQUEST_ELECTION })

export default id => async dispatch => {
  dispatch(requestElection())

  const response = await dispatch(apiFetch(`/elections/${id}`))

  if (!response.ok) {
    return dispatch(receiveElection(null))
  }

  const json = await response.json()

  if (json) {
    return dispatch(receiveElection(json))
  }
}
