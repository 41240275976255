import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Markdown } from 'components/sparkles'
import styles from './ExcuseExpander.module.scss'

interface Props {
  excuse: string // the text of the excuse, not the full object
}

const ExcuseExpander = ({ excuse }: Props) => {
  const { t } = useTranslation()
  const [expandExcuse, setExpandExcuse] = useState(false)

  // ref and clickaway listener to expand / collapse excuses with additional information
  const expanderRef = useRef<HTMLInputElement>() as any
  const handleClickOutside = (e: any) => {
    const ref = expanderRef.current
    if (ref && !ref.contains(e.target)) {
      setExpandExcuse(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })
  let needsMoreInfo, fullExcuseText
  let excuseText = excuse

  if (excuseText.length > 240) {
    fullExcuseText = excuseText // capture the full string
    needsMoreInfo = true

    excuseText = excuseText.substring(0, 240) + `...` // display a truncated version for long excuses with cue to expand
  }

  return (
    <div className={styles.excuseDescription} ref={expanderRef}>
      <Markdown
        source={needsMoreInfo && expandExcuse ? fullExcuseText : excuseText}
      />
      {needsMoreInfo && !expandExcuse && (
        <span
          className={styles.excuseMoreInfo}
          onClick={() => setExpandExcuse(true)}
        >
          {t(`forms:RequestBallot.moreInfo`)}
        </span>
      )}
    </div>
  )
}

export default ExcuseExpander
