import { RECEIVE_CHOICE_ELECTIONS } from 'actions/constants'
import { AnyAction } from 'redux'
import { Election } from 'types'

// Define the initial state
const initialState: Election[] = []

// Define the reducer function
const choiceElectionsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case RECEIVE_CHOICE_ELECTIONS:
      return action.payload
    default:
      return state
  }
}

export default choiceElectionsReducer
