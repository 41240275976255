import { Ballot, Place, UsState } from 'types'

export function findFieldForMostSpecificPlaceString(
  places: Place[],
  fieldName:
    | 'polling_place_url'
    | 'vbm_portal_url'
    | 'voter_check_registration_url'
    | 'voter_registration_url'
    | 'secretary_of_state_url',
): string | null {
  const result = findFieldForMostSpecificPlace(places, fieldName)
  return result ? `${result}` : null
}

export function findFieldForMostSpecificPlaceBool(
  places: Place[],
  fieldName: 'accepts_digital_signature',
): boolean {
  return !!findFieldForMostSpecificPlace(places, fieldName)
}

function findFieldForMostSpecificPlace(
  places: Place[],
  fieldName:
    | 'polling_place_url'
    | 'vbm_portal_url'
    | 'voter_check_registration_url'
    | 'voter_registration_url'
    | 'accepts_digital_signature'
    | 'secretary_of_state_url',
): string | boolean | null {
  places.sort(compareByMtfccAndGeoID)
  const placeWithField = places.find(place => place[fieldName])
  return placeWithField ? placeWithField[fieldName] : null
}

export function compareByMtfccAndGeoID(placeA: Place, placeB: Place) {
  const specificMtfccs = ['G4110', 'G4020', 'G4000'] // City, county, state
  if (
    specificMtfccs.includes(placeA.mtfcc) &&
    specificMtfccs.includes(placeB.mtfcc)
  ) {
    // If both are known values, then we can use the trailing numbers
    const valuePlaceA = parseInt(placeA.mtfcc.slice(1, 5))
    const valuePlaceB = parseInt(placeB.mtfcc.slice(1, 5))
    // The most specific value is largest, so we need a negative value if A is more specific
    return valuePlaceB - valuePlaceA
  } else {
    // Use length of GeoID, longer ID is more specific, so if A is more specific, this will be negative
    return placeB.geo_id.length - placeA.geo_id.length
  }
}

export const getStateName = (ballot: Ballot, usStates: Partial<UsState>[]) => {
  const stateInfo = usStates.find(
    state => state.short_name === ballot?.state?.toLowerCase(),
  )
  return stateInfo && stateInfo.name
}

export const multipleTimezoneStates = [
  'AL',
  'AK',
  'AZ',
  'FL',
  'ID',
  'IN',
  'KS',
  'KY',
  'MI',
  'ND',
  'OR',
  'SD',
  'TX',
]
