import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { StoreState } from 'types'
import { AnyAction } from 'redux'
import fetchSearchData, { searchInitiated } from 'actions/fetchSearchData'
import { AnalyticsContextType } from 'contexts/AnalyticsContext/AnalyticsContext'

const mapStateToProps = ({
  appConfig: {
    search: { saved: savedSearches },
  },
  browser,
  searchResults,
}: StoreState) => ({
  isSmallScreen: browser.lessThan.large,
  searching: searchResults.searching,
  savedSearches,
  searchInitiated: searchResults.searchInitiated,
  searchResults: searchResults.searchResults,
  searchTerm: searchResults.searchTerm,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, null, AnyAction>,
) => ({
  search: (searchTerm: string, analytics?: AnalyticsContextType) => {
    dispatch(fetchSearchData(searchTerm, analytics))
  },
  setSearchInitiated: (value: any) => {
    dispatch(searchInitiated(value))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
