import React, { useState } from 'react'
import { Endorsement } from 'types'
import './EndorsementList.scss'

interface Props {
  endorsements: Endorsement[]
  showFirst?: number
}

const EndorsementList: React.FC<Props> = ({ endorsements, showFirst = 6 }) => {
  const [collapsed, setCollapsed] = useState(true)

  if (!endorsements || endorsements.length === 0) {
    return null
  }

  const expandable = collapsed && endorsements.length > showFirst

  return (
    <div className="EndorsementList">
      <ul className="EndorsementList__List">
        {endorsements
          .slice(0, expandable ? showFirst : endorsements.length)
          .map((endorsement, key) => (
            <li key={key} style={{ borderLeftColor: `#${endorsement.color}` }}>
              {endorsement.website_url ? (
                <a
                  href={endorsement.website_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {endorsement.name}
                </a>
              ) : (
                <span key={key}>{endorsement.name}</span>
              )}
            </li>
          ))}
      </ul>
      {expandable && (
        <button
          className="EndorsementList__Button"
          onClick={() => setCollapsed(false)}
        >
          See more
        </button>
      )}
    </div>
  )
}

export default EndorsementList
