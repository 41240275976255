import ApiDate from './ApiDate'

/* Generates a collection of ApiDates with intervals of 30 minutes. These are
 * used in MAP TimeStep.
 */
const apiDateOptionFactory = (start: ApiDate, end: ApiDate) => {
  const intervalInMinutes = 30
  const startMinutes = start.getMinutesSinceMidnight()
  const endMinutes = end.getMinutesSinceMidnight()

  const intervals: ApiDate[] = []

  for (let i = startMinutes; i < endMinutes; i += intervalInMinutes) {
    const newDate = start.clone()
    newDate.setMinutesSinceMidnight(i)
    intervals.push(newDate)
  }

  return intervals
}

export default apiDateOptionFactory
