import { AnyAction } from 'redux'
import {
  COMPLETE_SECTION,
  RECEIVE_BALLOT,
  SELECT_ELECTION_CENTER_SECTION,
  SET_SHOW_ADDRESS_MODAL,
} from 'actions/constants'
import { ElectionCenterState } from 'types'

const initialState: ElectionCenterState = {
  completedSections: ['address'],
  showAddressModal: false,
}

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SELECT_ELECTION_CENTER_SECTION:
      return { ...state, currentSection: action.payload }
    case COMPLETE_SECTION: {
      if (!state.completedSections.find(value => value === action.payload)) {
        return {
          ...state,
          completedSections: state.completedSections.concat(action.payload),
        }
      }
      return state
    }
    case RECEIVE_BALLOT:
      return {
        ...state,
        completedSections: action.payload.voter_progress
          ?.electionCenterSections || ['address'],
      }
    case SET_SHOW_ADDRESS_MODAL:
      return {
        ...state,
        showAddressModal: action.payload.isShown,
        addressModalRedirect: action.payload.redirect,
      }
    default:
      return state
  }
}
