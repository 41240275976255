import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AddressForm from '../../../AddressForm/AddressForm'
import nameFormatter from 'utils/nameFormatter'
import './ActionHeader.scss'
import { Candidate } from 'types'

interface Props {
  candidate: Candidate
  logoUrl?: string
  tenantName: string
}
const ActionHeader: React.FC<Props> = ({ candidate, logoUrl, tenantName }) => {
  const { t } = useTranslation()

  return (
    <div className="ActionHeader">
      <div className="ActionHeader__Inner">
        {logoUrl && (
          <Link to="/" title="Home">
            <img alt="Logo" className="ActionHeader__Logo" src={logoUrl} />
          </Link>
        )}
        <div className="ActionHeader__Text">
          <h1>{t('sparkles:ActionHeader.title', { tenant: tenantName })}</h1>
          <p>
            {candidate
              ? t('sparkles:ActionHeader.subTitleWithCandidate', {
                  candidate: nameFormatter(candidate),
                })
              : t('sparkles:ActionHeader.subTitle')}
          </p>
        </div>
        <AddressForm
          buttonOutline={true}
          buttonVariant="white"
          size="sm"
          showTip={false}
        />
      </div>
    </div>
  )
}

export default ActionHeader
