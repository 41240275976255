import dateToInt from 'utils/dateToInt'
import dateToLocalISOString from 'utils/dateToLocalISOString'
import { RECEIVE_ELECTION, CLEAR_ELECTION } from 'actions/constants'

export default (state = null, action) => {
  switch (action.type) {
    case RECEIVE_ELECTION:
      const election = action.payload

      if (!election) {
        return null
      }

      const electionDay = dateToInt(election.election_day)
      const today = dateToInt(dateToLocalISOString(new Date()))
      if (today < electionDay) {
        return 'early'
      } else if (today === electionDay) {
        return 'election_day'
      } else {
        return 'closed'
      }
    case CLEAR_ELECTION:
      return null
    default:
      return state
  }
}
