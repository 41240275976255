import React, { useEffect, useState } from 'react'
import { IssueCategory } from 'types'
import { Markdown } from '../../atoms'
import IssueSortedItemsList from './IssueSortedItemsList'
import styles from './issueSortedItems.module.scss'

export interface IssueSortedItemsProps {
  analytics?(issue: IssueCategory): void
  blob?: string | null
  description?: string
  items: IssueCategory[]
  onIssueClick?(issue: IssueCategory): void
  placeholder: string
  showQuotationMarks?: boolean
  title: string
}

const IssueSortedItems: React.FC<IssueSortedItemsProps> = ({
  blob,
  description,
  items,
  onIssueClick,
  placeholder,
  showQuotationMarks = true,
  title,
}) => {
  const sortedIssues = items.sort((a, b) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  const [selectedIssue, onSelectIssue] = useState(sortedIssues[0])

  useEffect(() => {
    const newIssue =
      selectedIssue && items.find(issue => selectedIssue.id === issue.id)
    if (newIssue) {
      // If the same issue is there, update the selected issue to get the new stances
      onSelectIssue(newIssue)
    } else {
      // If the issue isn't found, just set it to the first one
      onSelectIssue(sortedIssues[0])
    }
  }, [sortedIssues, selectedIssue, items])

  if (!items || (!blob && items.length === 0)) {
    return null
  }

  return (
    <section data-testid="issue-section" className={styles.section}>
      <h2 className={styles.header}>{title}</h2>
      {description && <Markdown className={styles.blob} source={description} />}
      {sortedIssues.length > 0 && (
        <IssueSortedItemsList
          items={sortedIssues}
          onSelectIssue={issue => {
            onIssueClick && onIssueClick(issue)
            onSelectIssue(issue)
          }}
          placeholder={placeholder}
          selectedIssue={selectedIssue}
          showQuotationMarks={showQuotationMarks}
          title={title}
        />
      )}
      {blob && <Markdown className={styles.blob} source={blob} />}
    </section>
  )
}

export default IssueSortedItems
