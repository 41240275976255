import * as types from 'actions/constants'

export default (
  state = {
    loading: false,
    mapData: {},
    error: null,
  },
  { type, payload },
) => {
  switch (type) {
    case types.REQUEST_NORMALIZED_POSITION:
      return {
        ...state,
        loading: true,
      }
    case types.RECEIVE_NORMALIZED_POSITION:
      return {
        ...state,
        loading: false,
        mapData: payload,
      }
    case types.NORMALIZED_POSITION_ERROR:
      return {
        ...state,
        loading: false,
        error: 'Error loading map data',
      }
    default:
      return state
  }
}
