import { sliceLocale } from './sliceLocale'

/* Fake date/time parser. Expects a PostgreSQL formatted date string.
 *
 * Why do we have this? Javascript's built in Date class can only deal with
 * dates in the user's local timezone. We want to have the ability to display
 * polling day times in their actual time, not changed to be local to the user.
 *
 * Example: `2018-12-16T07:30:00.000-05:15`
 */

export default class ApiDate {
  year: number
  month: number
  day: number
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
  timezoneOffsetString: string
  timezoneOffset: number

  constructor(str: string) {
    // Date components
    this.year = parseInt(str.substring(0, 4), 10)
    this.month = parseInt(str.substring(5, 7), 10) - 1 // JS month weirdness!
    this.day = parseInt(str.substring(8, 10), 10)

    // Time components
    this.hours = parseInt(str.substring(11, 13), 10)
    this.minutes = parseInt(str.substring(14, 16), 10)
    this.seconds = parseInt(str.substring(17, 19), 10)
    this.milliseconds = parseInt(str.substring(20, 23), 10)

    // Timezone offset
    this.timezoneOffsetString = str.substring(23)
    const negator = str.substring(23, 24) === '-' ? -1 : 1
    this.timezoneOffset =
      (parseInt(str.substring(24, 26), 10) * 60 +
        parseInt(str.substring(27, 29), 10)) *
      negator
  }

  clone() {
    return new ApiDate(this.toString())
  }

  getAmPm(): 'am' | 'pm' {
    return this.hours >= 12 ? 'pm' : 'am'
  }

  getDay() {
    return this.day
  }

  getFullYear() {
    return this.year
  }

  getHours(clock = 24) {
    if (clock === 12) {
      const hours12 = this.hours % 12
      return hours12 ? hours12 : 12 // midnight
    } else {
      return this.hours
    }
  }

  getMilliseconds() {
    return this.milliseconds
  }

  getMinutes() {
    return this.minutes
  }

  getMinutesSinceMidnight() {
    return this.hours * 60 + this.minutes
  }

  getMonth() {
    return this.month
  }

  getSeconds() {
    return this.seconds
  }

  getTimezoneOffset() {
    return this.timezoneOffset
  }

  getTimezoneOffsetString() {
    return this.timezoneOffsetString
  }

  setMinutesSinceMidnight(minutesSinceMidnight: number) {
    this.hours = Math.floor(minutesSinceMidnight / 60)
    this.minutes = minutesSinceMidnight % 60
  }

  /**
   * Returns formatted date string, defalts to en-US
   *
   * TODO Remove once we have i18n-ed everything
   *
   * @param {Object} options see Date.toLocaleDateString options
   */
  toDateString(options: Intl.DateTimeFormatOptions) {
    return this.toLocaleDateString('en-US', options)
  }

  /**
   * Returns formatted date string
   *
   * We use JavaScript's built-in `Date.toLocaleDateString` function. We default
   * to `en-US` locale and `UTC` timezone. Setting the timezone to UTC helps us
   * stay away from JavaScript timezone nonsense.
   *
   * @param {String} locale see Date.toLocaleDateString options
   * @param {Object} options see Date.toLocaleDateString options
   */
  toLocaleDateString(locale: string, options: Intl.DateTimeFormatOptions) {
    // Browsers struggle with custom locales
    return new Date(
      Date.UTC(this.year, this.month, this.day),
    ).toLocaleDateString(sliceLocale(locale), { ...options, timeZone: 'UTC' })
  }

  /**
   * Rebuilds date string from individual components
   *
   * Note that the timezone offset is not altered. It re-uses the original
   * offset string.
   */
  toString() {
    const month = this._pad(this.month + 1)
    const day = this._pad(this.day)
    const hours = this._pad(this.hours)
    const minutes = this._pad(this.minutes)
    const seconds = this._pad(this.seconds)
    const milliseconds = this._pad(this.milliseconds, 3)
    const timezoneOffset = this.getTimezoneOffsetString()

    return `${this.year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneOffset}`
  }

  toTimeString(clock = 24, includeAmPm = false): string {
    const amPm = includeAmPm ? ` ${this.getAmPm()}` : ''
    const hours = this.getHours(clock)
    const minutes = this._pad(this.minutes)
    return `${hours}:${minutes}${amPm}`
  }

  _pad(num: number, length = 2) {
    const zeroes = new Array(length).fill('0').join('')
    return (zeroes + num).slice(-length)
  }
}
