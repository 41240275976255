import { Stance, Endorsement } from 'types'
import { IssueCategory } from 'types'

export function isStance(value: Stance | Endorsement): value is Stance {
  return (value as Stance).locale !== undefined
}

export function groupEndorsements(
  endorsements: Endorsement[],
): IssueCategory[] {
  const issueCategories: IssueCategory[] = []
  endorsements.forEach((endorse: Endorsement) => {
    const issueIndex = findIssueCategory(endorse, issueCategories)
    if (issueIndex < 0) {
      issueCategories.push(createIssueCategory(endorse))
    } else {
      issueCategories[issueIndex].items.push(endorse)
    }
  })
  return issueCategories
}

function findIssueCategory(
  endorsement: Endorsement,
  issueCategories: IssueCategory[],
) {
  for (let i = 0; i < issueCategories.length; i++) {
    if (issueCategories[i].id === endorsement.issue_id) {
      return i
    }
  }
  return -1
}

function createIssueCategory(endorsement: Endorsement): IssueCategory {
  return {
    key: endorsement.issue_key || 'other',
    id: endorsement.issue_id,
    items: [endorsement],
    name: endorsement.issue_name || 'Other',
  }
}
