import React, { useCallback, useRef } from 'react'
import { FieldProps } from 'formik'
import ReactSignatureCanvas from 'react-signature-canvas'
import styles from './SignatureField.module.scss'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

interface OwnProps {
  'aria-labelledby'?: string
  className: string
  error: boolean
  onEndSignature?(value: string | undefined): void
  height?: number
  width?: number
}

const SignatureField = ({
  field, // { name, value, onChange, onBlur }
  form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  onEndSignature,
  ...props
}: FieldProps & OwnProps) => {
  const signatureRef = useRef<ReactSignatureCanvas>(null)
  const { t } = useTranslation()
  const handleClear = useCallback(() => signatureRef?.current?.clear(), [])

  const { className, error, height = 200, width } = props

  return (
    <div className={styles.signatureContainer}>
      <ReactSignatureCanvas
        canvasProps={{
          'aria-labelledby': props['aria-labelledby'],
          className: classNames(className, styles.signaturePad, {
            [styles.signaturePad__error]: error,
          }),
          height,
          id: field.name,
          width,
        }}
        ref={signatureRef}
        onEnd={() => {
          const signatureValue = signatureRef?.current?.toDataURL()
          setFieldValue(field.name, signatureValue)
          onEndSignature && onEndSignature(signatureValue)
        }}
      />

      <button className={styles.footer} onClick={handleClear} type="button">
        {t('sparkles:SignatureField.clear')}
      </button>
    </div>
  )
}

export default SignatureField
