import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown } from '@fortawesome/pro-solid-svg-icons/faThumbsDown'
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons/faThumbsUp'
import { Headshot, SelectionComment, ShareCard } from 'components/sparkles'
import nameFormatter from 'utils/nameFormatter'
import candidatePartyNames from 'utils/candidatePartyNames'
import './PositionShareCard.css'
import { SharedBallotSelection } from 'types'

interface Props {
  name?: string
  isReadOnly?: boolean
  onCommentSubmit?: () => {}
  position: { name: string; selections: SharedBallotSelection[] }
}

const PositionShareCard: React.FC<Props> = ({
  name,
  isReadOnly = true,
  onCommentSubmit,
  position,
}) => {
  return (
    <ShareCard className="PositionShareCard" title={position.name}>
      <ul>
        {position.selections.map(selection => (
          <li className="PositionShareCard__Selection" key={selection.id}>
            <div className="PositionShareCard__Candidate">
              {!!selection.candidate && (
                <Headshot person={selection.candidate} />
              )}
              <div className="PositionShareCard__CandidateName">
                <div>
                  <h4>
                    {!!selection.candidate &&
                      nameFormatter(selection.candidate)}
                  </h4>
                  {!!selection?.candidate?.parties &&
                    selection.candidate.parties.length > 0 &&
                    candidatePartyNames(selection.candidate.parties)}
                </div>
                {selection.yes_no && (
                  <div
                    className={classNames('PositionShareCard__Retention', {
                      'PositionShareCard__Retention--danger':
                        selection.yes_no === 'no',
                      'PositionShareCard__Retention--success':
                        selection.yes_no === 'yes',
                    })}
                  >
                    <FontAwesomeIcon
                      className={classNames('PositionShareCard__Icon', {
                        'PositionShareCard__Icon--danger':
                          selection.yes_no === 'no',
                        'PositionShareCard__Icon--success':
                          selection.yes_no === 'yes',
                      })}
                      icon={
                        selection.yes_no === 'yes' ? faThumbsUp : faThumbsDown
                      }
                    />
                    {selection.yes_no === 'yes' ? 'Retain' : 'Do not retain'}
                  </div>
                )}
              </div>
            </div>

            <SelectionComment
              author={name ? name : undefined}
              editable={onCommentSubmit && !isReadOnly}
              onSubmit={onCommentSubmit}
              selection={selection}
            />
          </li>
        ))}
      </ul>
    </ShareCard>
  )
}

export default PositionShareCard
