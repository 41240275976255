import * as types from 'actions/constants'
import { AnyAction } from 'redux'

export default (state = false, action: AnyAction) => {
  switch (action.type) {
    case types.TOGGLE_NO_ELECTION_AVAILABLE:
      return action.value
    default:
      return state
  }
}
