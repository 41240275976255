import * as types from 'actions/constants'

const positions = (
  state = {
    isFetching: false,
    items: [],
    lastUpdated: null,
  },
  action,
) => {
  switch (action.type) {
    case types.REQUEST_POSITIONS_BY_STATE:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case types.RECEIVE_POSITIONS_BY_STATE:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.payload,
        lastUpdated: action.receivedAt,
      })
    default:
      return state
  }
}

export default (state = {}, action) => {
  switch (action.type) {
    case types.RECEIVE_POSITIONS_BY_STATE:
    case types.REQUEST_POSITIONS_BY_STATE:
      return Object.assign({}, state, {
        [action.shortName]: positions(state[action.shortName], action),
      })
    default:
      return state
  }
}
