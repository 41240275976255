import React from 'react'

export const RedirectArrow = () => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Arrow pointing to the top right direction of your screen</title>
    <g
      id="Updates-based-on-feedback"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="3.2_VBM_registration_no_register-Copy-3"
        transform="translate(-165.000000, -439.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g
          id="noun_top-right_294260"
          transform="translate(165.000000, 439.000000)"
        >
          <path
            d="M0,0 L0,11.8016667 C0,17.7346667 7.173,20.7056667 11.3683333,16.5106667 L16.434,11.4446667 L6.333,11.4446667 L6.333,8.44466667 L21.6663333,8.44466667 L21.6663333,23.778 L18.6663333,23.778 L18.6663333,13.4546667 L13.4893333,18.6316667 C9.294,22.827 12.2653333,30 18.198,30 L30,30 L30,0 L0,0 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
