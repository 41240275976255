import * as types from 'actions/constants'
import apiFetch from './apiFetch'

const savedBallot = payload => ({
  type: types.SAVED_BALLOT,
  payload,
})
const savingBallot = () => ({ type: types.SAVING_BALLOT })

export default values => async dispatch => {
  dispatch(savingBallot())

  const response = await dispatch(
    apiFetch(`/ballot`, {
      method: 'PATCH',
      body: JSON.stringify(values),
    }),
  )

  if (!response.ok) {
    return
  }

  const json = await response.json()

  if (json) {
    return dispatch(savedBallot(json))
  }
}
