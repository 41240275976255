import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Markdown } from '../../atoms'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { enterKeyPress } from 'utils/enterKeyPress'
import styles from './radioGroup.module.scss'

interface RadioGroupProps<T> {
  radioOptions: RadioOption<T>[]
  selected: T
  setSelectedChoice(
    event: React.MouseEvent | React.KeyboardEvent | React.ChangeEvent,
    choice: T,
  ): void
  showSuccessMessage: boolean
  shouldShowSuccessMessage: boolean
}

type RadioOption<T> = {
  value: T
  label: string
  id: string
}

type ValueType = string | null | undefined

const RadioGroup = <T extends ValueType>({
  radioOptions,
  selected,
  setSelectedChoice,
  showSuccessMessage,
  shouldShowSuccessMessage,
}: RadioGroupProps<T>) => {
  const { t } = useTranslation()

  const handleClick = (
    event: React.KeyboardEvent | React.MouseEvent | React.ChangeEvent,
    choice: T,
  ) => {
    event.stopPropagation()
    setSelectedChoice(event, choice)
  }

  const handleKeyPress = (
    event: React.KeyboardEvent,
    option: RadioOption<T>,
  ) => {
    if (enterKeyPress(event)) {
      setSelectedChoice(event, option.value)
    }
  }
  return (
    <>
      <fieldset className={styles.container} data-testid="radiogroup">
        {radioOptions.map((option: RadioOption<T>) => (
          <div
            className={styles.radioItem}
            key={option.id}
            onClick={event => handleClick(event, option.value)}
            onKeyPress={(event: React.KeyboardEvent) =>
              handleKeyPress(event, option)
            }
            role="radiogroup"
            tabIndex={0}
          >
            <input
              checked={selected === option.value}
              className={styles.radioInput}
              data-testid="radiogroup-input"
              id={option.id}
              type="radio"
              onClick={event => handleClick(event, option.value)}
              onChange={event => handleClick(event, option.value)}
            />
            <span
              aria-hidden="true"
              aria-checked={selected === option.value}
              aria-labelledby={`radioLabel-${option.id}`}
              className={styles.radioControl}
              data-testid={`radiogroup-button-${option.id}`}
              onKeyPress={(event: React.KeyboardEvent) =>
                handleKeyPress(event, option)
              }
              onClick={event => handleClick(event, option.value)}
            ></span>
            <label
              className={styles.radioLabel}
              id={`radioLabel-${option.id}`}
              htmlFor={option.id}
            >
              {option.label}
            </label>
          </div>
        ))}
      </fieldset>

      {shouldShowSuccessMessage && showSuccessMessage && (
        <div
          className={classNames(
            styles.success,
            selected === 'yes' ? styles.yes : styles.no,
          )}
          data-testid="radiogroup-success"
        >
          <FontAwesomeIcon icon={faCheck} />
          <Markdown
            source={
              selected === 'yes'
                ? t('sparkles:Candidacy.yesSuccess')
                : t('sparkles:Candidacy.noSuccess')
            }
          />
        </div>
      )}
    </>
  )
}

export default RadioGroup
